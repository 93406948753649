.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: $container-padding;
    padding-right: $container-padding;
    max-width: $container-max-width + ($container-padding * 2);

    @include media-breakpoint-down(sm) {
        padding-left: $container-padding / 2;
        padding-right: $container-padding / 2;
    }

    &--narrow {
        max-width: $container-max-width--narrow;
    }

    &--full-width {
        max-width: none;
        width: 100%;
    }
}