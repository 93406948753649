/*** IMPORT CONFIG ***/
@import "config/variables";
@import "config/icons";

/*** IMPORT VENDOR ***/
@import "vendor/baguettebox";
@import "vendor/flatpickr";

/**
 * IMPORT FONTS
 * ------------
 * insert fonts in .build/.fonts
 * execute gulp fonts
 */
@import "fonts/imported-google-fonts";
@import "fonts/imported-meow-font";

/*** IMPORT BASIC STUFF ***/
@import "basic/functions";
@import "basic/mixins";
@import "basic/reboot";
@import "basic/helpers";
@import "basic/typography";

/*** IMPORT LAYOUT ***/
@import "layout/base";
@import "layout/header";
@import "layout/header-bar";
@import "layout/header-media";
@import "layout/main-content";
@import "layout/footer";

/*** IMPORT COMPONENTS ***/
@import "components/container";
@import "components/logo";
@import "components/buttons";
@import "components/main-nav";
@import "components/cols";
@import "components/tables";
@import "components/icons";
@import "components/forms";
@import "components/page-intro";
@import "components/header-booking";
@import "components/search-overlay";
@import "components/search";
@import "components/booking";

/*** IMPORT AREAS ***/
@import "areas/area";
@import "areas/area--text";
@import "areas/area--textmedia";
@import "areas/area--media";
@import "areas/area--teaser";
@import "areas/area--quote";
@import "areas/area--disruptor";
@import "areas/area--contentcontainer";


/* Contact Map */


.contact_map {
    height: 50vh;
    z-index: 1;
    overflow: hidden;
    @include fluidAll('margin-bottom', 20px, 50px);
}

/* Alert */

.alert {
    @include fluid(padding, 30px);
    @include fluid(border-width, 2px);
    border-style: solid;
    border-color: $brand-primary;
    @include fluid(border-radius, 20px);
    background: rgba($brand-primary, 0.2);
    color: $brand-primary;
    text-align: center;
    @include fluid(margin-bottom, 30px);
}