.form-container,
form.formbuilder {
    fieldset {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    label {
        display: block;
        margin-bottom: 5px;
        width: 100%;
        line-height: 1.2;

        &.required {
            &:after {
                content:"*";
            }
        }
    }


    input[type=email],
    input[type=text],
    input[type=date],
    input[type=password],
    select,
    textarea {
        padding: $formfield-padding;
        width: 100%;
        color: $formfield-color;
        background: $formfield-bg;
        border: $formfield-border-width solid $formfield-border-color;
        border-radius: $formfield-border-radius;
        line-height: 1.5;

        &:focus {
            color: $formfield-focus-color;
            background: $formfield-focus-bg;
            outline: 0;
            box-shadow: 0 0 0 0.25rem $formfield-focus-border-color;
        }

        &[readonly], &[disabled] {
            color: rgba($formfield-color, .5);
            background-color: rgba($black, 0.1);
            border-color: rgba($formfield-border-color, .3);
        }
    }

    textarea {
        min-height: 120px;
    }

    .checkbox,
    .radio {
        position: relative;
        padding-left: 20px;

        input[type="checkbox"],
        input[type="radio"] {
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            height: 20px;
        }
    }

    .cols {
        grid-column-gap: $form-gutter-width;
        grid-row-gap: $form-gutter-width;
        margin-bottom: $form-gutter-width;
    }
}

.form-group {
    margin-bottom: $form-gutter-width;

    .cols {
        margin-bottom: 0;
        grid-column-gap: $form-gutter-width * 2;
    }

    &.has-datepicker {
        position: relative;

        &:after {
            display: block;
            content: '';
            position: absolute;
            right: 8px;
            bottom: 8px;
            width: 25px;
            height: 25px;
            @include updateIcon('calendar-alt', '');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }
    }

    .cols & {
        margin-bottom: 0;
    }

    .numberpicker {
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
            background: none;
            border: none;
            font-size: 30px;
            font-weight: $font-weight-bold;
            color: $white;
            max-width: 3rem;
            text-align: center;
            padding: 0 !important;
        }

        button {
            background: none;
            border: none;
            width: 20px;
            height: 20px;
            position: relative;
            padding: 0;

            &.minus:before {
                content: '';
                height: 2px;
                width: 100%;
                background-color: $black;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            &.plus {

                &:before {
                    content: '';
                    height: 2px;
                    width: 100%;
                    background-color: $black;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:after {
                    content: '';
                    height: 100%;
                    width: 2px;
                    background-color: $black;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
}

/* Error Message */

.error-message {
    border: 1px solid red;
    background: rgba(red, .2);
    @include fluid(padding, 20px);
    text-align: center;
    @include fluid(margin-bottom, 40px);
}

