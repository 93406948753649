.search-overlay {
    position: fixed;
    z-index: 9;
    background-color: rgba($lighter-gray, 0.9);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    max-height: 0;

    &.search-overlay--focus {
        opacity: 1;
        max-height: 1500px;
        transition: opacity 0.3s cubic-bezier(.7,0,.9,1);
    }

    &--content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    &--fieldset {
        width: 80vw;
        display: grid;
        grid-template-columns: 5fr 1fr;
        max-width: 42rem;
    }

    .btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}