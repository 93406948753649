/* meow-script-regular - latin */
@font-face {
    font-family: 'Meow Script';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/googlefonts/meow-script-v3-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/googlefonts/meow-script-v3-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/googlefonts/meow-script-v3-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/googlefonts/meow-script-v3-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/googlefonts/meow-script-v3-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/googlefonts/meow-script-v3-latin-regular.svg#MeowScript') format('svg'); /* Legacy iOS */
    font-display: swap;
}