.main-content {
    padding-top: 60px;

    @include media-breakpoint-up(lg) {
        padding-top: 100px;
    }

    @at-root  {
        :root {
            --scrollbarWidth: 0px;
        }
    }
}