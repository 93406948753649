.area--quote {

    &-inner {
        display: flex;

        p {
            font-size: inherit;
            margin-bottom: 0;
        }
    }

    .area--text:first-child {
        margin-right: 15px;
        width: 100%;

        @include media-breakpoint-up(md) {
            margin-right: 30px;
        }
    }

    .area--text:nth-child(2) {
        margin-left: 15px;
        width: 100%;
        display: flex;
        align-items: center;
        @include fluid-property('line-height', $viewport-width-min, $viewport-width-max, 25px, 30px);

        @include media-breakpoint-up(md) {
            margin-left: 30px;
        }
    }
}