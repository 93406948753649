$logo-width: 100px;
$logo-height: 50px;
$logo-ratio: (strip-units($logo-height) * 100) / strip-units($logo-width);

.logo {
    background-image: url('../images/layout/svg/logo-negative.svg');
    width: 220px;
    height: 80px;
    background-size: 100% auto;
    background-position: left center;
    background-repeat: no-repeat;
}