@mixin generateIconSet($icon-list) {
    @for $i from 1 through length($icon-list) {
        $icon-name: nth($icon-list, $i);

        .icon--#{$icon-name} {
            //@debug '#{$icon-name}.svg';
            background-image: svg-load('#{$icon-name}.svg');
        }
    }
}

@mixin generateIconSetColorVariants($icon-list, $color, $color-class) {
    @for $i from 1 through length($icon-list) {
        $icon-name: nth($icon-list, $i);

        .icon-color--#{$color-class}.icon--#{$icon-name} {
            background-image: svg-load('#{$icon-name}.svg', fill=$color);
        }
    }
}

@mixin setIcon($icon-name, $color, $size: 1em) {
    display: inline-block;
    vertical-align: middle;
    width: $size;
    height: $size;
    line-height: $size;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    background-image: svg-load('#{$icon-name}.svg', fill=$color);
}

@mixin updateIcon($icon-name, $color) {
    background-image: svg-load('#{$icon-name}.svg', fill=$color);
}