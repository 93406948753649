.area--content-container {
    @include fluid-property('padding-top', $viewport-width-min, $viewport-width-max, 60px, 100px);
    @include fluid-property('padding-bottom', $viewport-width-min, $viewport-width-max, 60px, 100px);
    background: $lighter-gray;
    color: $text-color;
    position: relative;

    h2 {
        &.margin-top {
            @include media-breakpoint-up(md) {
                margin-top: 50px;
            }
        }
    }

    &.branch {
        &:before {
            content: svg-load('../images/layout/svg/branch-big.svg');
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: -2;

            @include media-breakpoint-down(md) {
                content: unset;
            }
        }
    }
}
