.header-bar {
    background-color: $white;
    z-index: 2;
    width: 100%;
    height: 55px;
    padding: 8px 15px;

    @include media-breakpoint-up(xl) {
        height: 100%;
        background-color: transparent;
        margin-top: 30px;
        z-index: 4;
        padding: 0 70px;
    }

    .btn {
        border-color: $darker-gray;
    }

    &--mobile {
        position: fixed;
        height: 55px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        z-index: 2;
        opacity: 0;
        max-height: 0;
        background-color: $white;
        //transition: all 0.3s ease-in-out;

        @include media-breakpoint-up(xl) {
            display: none;
        }

        &.fixed {
            max-height: 55px;
            opacity: 1;
            background-color: $white;
            transition: all 0.3s ease-in-out;
        }

        .main-nav--toggle {
            position: relative;
            right: unset;
            top: unset;

            .hamburger-box--inner {
                background-color: $black;

                &:before, &:after {
                    background-color: $black;
                }
            }
        }

        .btn {
            align-self: center;
            margin: 0 20px;
            border-color: $darker-gray;
            color: $darker-gray;
        }

        &-content {
            display: flex;
        }

        .logo {
            //width: 200px;
            height: 100%;
            background-size: contain;
            background-image: url("../images/layout/svg/logo-castello-lila.svg");
            display: block;
            width: 100%;
        }
    }

    &--content, &--meta {
        display: flex;
    }

    &--content {
        justify-content: space-between;
        align-items: center;
        height: 100%;

        @include media-breakpoint-up(xl) {
            justify-content: flex-end;
        }

        .btn {

            @include media-breakpoint-up(xl) {
                display: none;
            }
        }

        a:not(.btn) {
            background: svg-load('../images/layout/svg/btn-border.svg') no-repeat;
            border-radius: 100%;
            transition: background-color 0.3s;

            &:hover {
                background-color: $brand-primary;

                i {
                    filter: brightness(0) invert(1);
                }
            }
        }
    }

    &--meta {

        .icon {
            height: 2em;
            line-height: 2em;
            width: 2em;
            background-size: unset;

            @include media-breakpoint-up(xl) {
                filter: saturate(0) brightness(0) invert(100%) opacity(1);
                height: 40px;
                width: 40px;
            }

            &--loupe {
                cursor: pointer;
                filter: brightness(0);
            }
        }
    }

    .header--social--link {

        @include media-breakpoint-up(xl) {
            margin-right: 20px;
        }
    }

    .lang-nav {

        li {
            display: inline-block;
            line-height: 36px;
            width: 32px;
            text-align: center;

            @include media-breakpoint-up(xl) {
                width: 40px;
                height: 40px;
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }
            }

            a {
                color: $darker-gray;
                text-transform: uppercase;

                @include media-breakpoint-up(xl) {
                    color: $white;
                    line-height: 40px;
                    display: block;
                }
            }
        }
    }
}