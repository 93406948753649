table.contenttable,
.area--text table {
    width: 100%;
    border: none;
    min-width: 550px;

    th,
    td {
        //padding: $table-padding-y $table-padding-x;
        padding: 15px 30px;

        &:first-child {
            //padding-left: $table-padding-x * 2;
        }
        &:last-child {
            //padding-right: $table-padding-x * 2;
        }

        //min-width: 25vw;

        @include media-breakpoint-up(md) {
            //min-width: 0;
        }
    }

    thead {
        tr:first-child th {
            background-color: $table-head-bg;
            color: $table-head-color;
        }

        tr:not(:first-child) th {
            background-color: $table-head-secondary-bg;
            color: $table-head-secondary-color;
        }
    }

    td {
        border-bottom: 1px solid $table-border-color;
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }

    // Spezial Responsive Table
    @include media-breakpoint-down(md) {
        table, thead, tbody, tr, th, td {
            //display: block;
        }

        tr:last-child {
            td:last-child {
                //border-bottom: 0;

                &:before {
                    //border-bottom: 0;
                }
            }
        }

        &.has-thead {
            thead tr:last-child {
                //display: none;
            }

            tbody {
                td {
                    //display: flex;
                    //position: relative;
                    //padding: $table-padding-y $table-padding-x $table-padding-y 0;
                    //align-items: center;
                    //align-content: center;

                    &:before {
                        //display: flex;
                        //align-items: center;
                        //align-content: center;
                        //content: attr(data-label);
                        //margin-top: -$table-padding-y;
                        //margin-bottom: -$table-padding-y;
                        //margin-right: $table-padding-x;
                        //padding: $table-padding-y $table-padding-x;
                        //width: 45%;
                        //flex: 0 0 45%;
                        //align-self: stretch;

                    }
                }
            }

            &:not(.multiple-thead-rows) {
                tbody td:before {
                    //background: $table-head-bg;
                    //border-bottom: 1px solid $table-border-color;
                    //color: $table-head-color;
                }
            }
        }
    }
}

// normale responsive table (horizontal scroll)
.table-responsive {
    @include media-breakpoint-down(sm) {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    &--large {
        table {
            min-width: 650px;
        }
    }
}

