/*** Variables ***/
$search--color: #333333;
$search--bg-gray: #e4e4e4;
$search--border-light: rgba(0, 0, 0, .1);

$search--form--input-height: 40px;
$search--form--input-bg: #fff;
$search--form--input-color: $search--color;
$search--form--input-border-color: $search--border-light;

$search-btn--color: #fff;
$search-btn--color-hover: #fff;
$search-btn--bg: $brand-primary;
$search-btn--bg-hover: $search--color;
$search-btn-outline--color: $search--color;
$search-btn-outline--color-hover: #fff;
$search-btn-outline--bg: #fff;
$search-btn-outline--bg-hover: $search--color;
$search-btn-outline--border: $search--border-light;
$search-btn-outline--border-hover: $search--color;

$search-pagination--color: $search--color;
$search-pagination--border-color: $search--bg-gray;
$search-pagination--bg: transparent;
$search-pagination--bg-active: $search-pagination--border-color;

$icon-color-filter--white: saturate(0) brightness(0) invert(100%) opacity(1);

/*** Styles ***/
.search {
    color: $search--color;

    // Search Form
    &--form {
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
        margin-bottom: 50px;

        form {
            display: flex;
            flex-wrap: nowrap;

            @media (max-width: 575px) {
                flex-wrap: wrap;
                width: 100%;
            }
        }

        input[type="text"] {
            margin: 0 5px;
            padding: 0 15px;
            height: $search--form--input-height;
            border: 2px solid $search--form--input-border-color;
            border-radius: $search--form--input-height / 2;

            @media (max-width: 575px) {
                margin: 0 0 10px 0;
                width: 100%;
            }
        }

        button[type="submit"] {
            margin: 0 5px;
            padding: 12px;
            width: auto;

            @media (max-width: 575px) {
                margin: 0;
                width: 100%;
            }

            .search--icon {
                margin: 0;
            }
        }
    }

    // Search Results
    &--results {

        &--count {
            margin-bottom: 50px;
            text-align: center;
        }
    }

    &--result {
        header {
            h3 {
                margin-bottom: .5em;
            }
        }

        p {
            margin-bottom: 1em;
        }
        &:last-child {
            @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, 30px, 60px);
            @include fluid-property('margin-bottom', $viewport-width-min, $viewport-width-max, 30px, 60px);
        }
    }

    // Pagination
    &--pagination {
        margin: 50px 0;
        padding: 0;
        list-style-type: none;
        text-align: center;

        li {
            display: inline-block;
            margin-bottom: 4px;

            &.current {
                a {
                    &, &:hover {
                        background: $search-pagination--bg-active;
                    }
                }
            }

            a {
                display: block;
                width: 50px;
                height: 50px;
                border: 2px solid $search-pagination--border-color;
                border-radius: 100%;
                color: $search-pagination--color;
                line-height: 46px;
                font-size: 17px;
                transition: background-color .3s;

                &:hover {
                    background: rgba($search-pagination--bg-active, .3);
                }
            }
        }
    }

    // Buttons
    &--btn {
        display: inline-block;
        padding: 12px 25px;
        width: 100%;
        line-height: 1.3;
        background: $search-btn--bg;
        color: $search-btn--color;
        border: none;
        border-radius: 40px;
        text-transform: uppercase;
        font-size: 13px;
        text-align: center;
        cursor: pointer;
        transition: background-color .3s, color .3s;

        &:hover {
            color: $search-btn--color-hover;
            background-color: $search-btn--bg-hover;
        }

        .search--icon {
            margin: 0 4px 0 -8px;
            filter: $icon-color-filter--white;
            transition: filter .3s;
            font-size: 16px;
            vertical-align: bottom;
        }
    }

    // Icons
    &--icon {
        display: inline-block;
        width: 1em;
        height: 1em;
        line-height: inherit;
        vertical-align: baseline;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50% 50%;

        &--search {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 17'%3E%3Cpath d='M6.067 1.545c1.207 0 2.343.47 3.197 1.325a4.49 4.49 0 011.324 3.196c0 1.208-.47 2.343-1.324 3.198a4.491 4.491 0 01-3.197 1.324A4.494 4.494 0 012.87 9.264a4.527 4.527 0 010-6.394 4.49 4.49 0 013.197-1.325zm0-1.545a6.067 6.067 0 10.001 12.133A6.067 6.067 0 006.067 0z'/%3E%3Cpath d='M14.596 15.726l1.13-1.131-4.941-4.941-1.13 1.131 4.941 4.94z'/%3E%3Cpath d='M10.784 9.927l4.669 4.668-.858.858-4.668-4.668.857-.858zm0-.546L9.38 10.786 14.595 16 16 14.596l-5.216-5.215z'/%3E%3C/svg%3E");
        }
    }
}

.searchpage {
    .page-header {
        height: 75vh;
    }

    .header-booking {
        bottom: -70px;
    }
}