.area--teaser {
    position: relative;
    padding-bottom: 60px;

    @include media-breakpoint-down(lg) {
        padding-bottom: 50px;
        padding-top: 50px;
    }

    &.branch {
        &:before {
            content: svg-load('../images/layout/svg/branch-big.svg');
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: -2;
        }
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 100px;
        bottom: 0;
        background-color: $lighter-gray;
        z-index: -3;

        @include media-breakpoint-down(lg) {
            top: 0;
        }
    }

    .grid-container {
        @include fluid-property('height', 991px, 1800px, 850px, 1080px);
        display: grid;
        grid-template-columns: 0.8fr 1fr 1.2fr 1fr;
        grid-template-rows: 1fr 2fr 0.2fr 1fr;
        gap: $grid-gutter-width $grid-gutter-width;
        grid-auto-flow: row;
        grid-template-areas:
            ". . teaser-right teaser-right"
            "teaser-left teaser-left teaser-right teaser-right"
            "teaser-left teaser-left teaser-bottomRightMiddle teaser-bottomRight"
            "teaser-bottomLeft teaser-bottomLeftMiddle teaser-bottomRightMiddle teaser-bottomRight"
            "teaser-bottomLeft teaser-bottomLeftMiddle teaser-bottomRightMiddle teaser-bottomRight";

        @include media-breakpoint-down(lg) {
            height: unset;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
            gap: $grid-gutter-width/2 $grid-gutter-width/2;
            grid-template-areas:
                "teaser-right teaser-right"
                "teaser-right teaser-right"
                "teaser-left teaser-left"
                "teaser-left teaser-left"
                "teaser-bottomRight teaser-bottomRightMiddle"
                "teaser-bottomLeft teaser-bottomLeftMiddle";
        }
    }

    .teaser-bottomLeft {
        grid-area: teaser-bottomLeft;
        background-color: $lighter-violet;
        transition: background-color 0.3s;

        &:hover {
            background-color: $brand-primary;
        }
    }

    .teaser-bottomLeftMiddle {
        grid-area: teaser-bottomLeftMiddle;
        background-color: $brand-primary;
        transition: background-color 0.3s;

        &:hover {
            background-color: $lighter-violet;
        }
    }

    .teaser-bottomRight { grid-area: teaser-bottomRight; }

    .teaser {
        position: relative;
        cursor: pointer;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        overflow: hidden;

        a {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
        }

        @include media-breakpoint-down(lg) {
            min-height: 175px;
        }

        @include media-breakpoint-up(sm) {
            &:hover {
                .teaser-content {
                    max-height: 500px;
                    transition: max-height 0.5s ease-in-out;
                    margin-bottom: 20px;
                }

                &:before {
                    opacity: 0.6;
                }

                img {
                    transform: scale(1.3);
                }
            }
        }

        &:before {
            content: '';
            position: absolute;
            height: 100%;
            max-height: 900px;
            width: 100%;
            background-color: $black;
            opacity: 0.3;
            z-index: -1;
            transition: opacity 0.4s;
        }

        &-content {
            max-height: 0;
            height: auto;
            width: 100%;
            transition: max-height 0.5s ease-in-out, margin-bottom 0.5s ease-in-out;
            padding: 0 20px 0px 20px;
            color: $white;
        }

        .area--thumbnail {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            z-index: -2;

            img {
                position: absolute;
                height: 100%;
                width: 100%;
                object-fit: cover;
                transition: transform 0.3s ease-in-out;
            }
        }

        h3, h4 {
            color: $white;
            padding-right: 20px;
            padding-left: 20px;
            padding-top: 20px;
            padding-bottom: 10px;
            width: 100%;
        }
    }

    .teaser-bottomRightMiddle {
        grid-area: teaser-bottomRightMiddle;

        &:before {
            background-color: $brand-primary;
        }
    }

    .teaser-right {
        grid-area: teaser-right;
    }

    .teaser-left {
        grid-area: teaser-left;
    }

    .teaser-bottomLeft, .teaser-bottomLeftMiddle {
        align-items: center;
        padding-top: 15px;
        overflow: hidden;

        i {
            height: 110px;
            width: 110px;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
}