// Basic Variables
$font-size-root: 18px;
$line-height-base: 1.5;
$wrapper-max-width: (1800px / $font-size-root) * 1rem;

// Basic Colors
$white:    #fff !default;
$gray: #e0e0e0;
$black:    #000 !default;

$body-bg: $white;
$text-color: $black;
$brand-primary: #83227A;
$lighter-violet: #A84D90;
$darker-gray: #575756;
$lighter-gray: #F2F2F2;

// Typography
$font-family-base: 'Raleway', sans-serif;
$font-family-special: 'Meow Script', cursive;
$font-size-base: $font-size-root;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: 900;

$h1-font-size:                130px;
$h2-font-size:                90px;
$h3-font-size:                70px;
$h4-font-size:                50px;
$h5-font-size:                35px;
$h6-font-size:                $font-size-base !default;

$h1-min-font-size:            60px;
$h2-min-font-size:            50px;
$h3-min-font-size:            40px;
$h4-min-font-size:            30px;
$h5-min-font-size:            20px ;
$h6-min-font-size:            $h6-font-size;

$lead-font-size:			  20px;
$lead-min-font-size:		  18px;

$headings-margin-bottom: 1em;
$paragraph-margin-bottom: 1em;

// Fluid Typo Viewport
$viewport-width-min: 768px;
$viewport-width-max: 1200px;

// Containers
$container-padding: 60px;
$container-max-width: 1120px;
$container-max-width--narrow: 960px;

// Grid
$grid-gutter-width: 30px;

// Links
$link-color: $brand-primary;
$link-hover-color: darken($brand-primary, 10%);
$link-decoration: none;
$link-hover-decoration: none;

// Tables
$table-padding-x: 15px;
$table-padding-y: 10px;
$table-border-color: rgba($black, .15);
$table-head-color: $white;
$table-head-bg: $brand-primary;
$table-head-secondary-color: $text-color;
$table-head-secondary-bg: $table-border-color;

// Buttons
$btn-font-family: $font-family-base;
$btn-font-size: $font-size-base;
$btn-font-weight: $font-weight-bold;
$btn-line-height: 1;
$btn-padding: 0.675em 1em;
$btn-border-width: 1px;
$btn-border-radius: 5px;
$btn-disabled-opacity: 0.4;
$btn-primary-bg: $brand-primary;

// Forms
$form-gutter-width: 15px;
$formfield-color: $text-color;
$formfield-bg: $white;
$formfield-padding: 8px 12px;
$formfield-border-width: 1px;
$formfield-border-radius: 0;
$formfield-border-color: $black;
$formfield-focus-color: $text-color;
$formfield-focus-border-color: rgba($brand-primary, .5);
$formfield-focus-bg: $formfield-bg;

// Breakpoints
$grid-breakpoints: (
        xs: 370px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
        xxxl: 1600px,
        outer: 1920px,
) !default;

$maxWidthDesktop: map-get($grid-breakpoints, xl);
$maxWidthDesktopPlus: map-get($grid-breakpoints, xxxl);
$outerLimit: map-get($grid-breakpoints, outer);