.footer {

    .container {
        position: relative;
    }

    &--main {
        background-color: $brand-primary;
        color: $white;
        padding: 70px 0;
        font-size: 14px;
        line-height: 30px;

        p {
            margin-bottom: 0;
        }

        &--inner {
            display: flex;
            flex-wrap: wrap;
        }

        &-logo {
            flex: 3 0 auto;
            margin-right: 30px;

            .logo {
                display: block;
                max-width: 300px;
                min-width: 250px;
                width: 100%;
                height: 150px;
                background-size: 100% auto;
                filter: saturate(0) brightness(0) invert(100%) opacity(1);
            }
        }

        .footer--adress {
            flex: 2 0 auto;
            margin-right: 20px;

            i {
                height: 3em;
                width: 3em;
            }
        }

        .footer--main-links {
            flex: 1 0 auto;
            display: flex;
            justify-content: space-evenly;
            min-width: 260px;

            a:not(.x-btn) {
                padding: 10px 5px 10px 15px;
                display: block;
                position: relative;

                &:before {
                    content: '';
                    height: 7px;
                    width: 7px;
                    border-right: 2px solid $white;
                    border-top: 2px solid $white;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%) rotate(45deg);
                }
            }

            ul:first-child {
                margin-right: 10px;
            }

            @include media-breakpoint-down(sm) {
                flex-direction: column;

                ul {
                    margin-bottom: 40px;
                }
            }
        }

        .col {
            @include media-breakpoint-down(sm) {
                margin-bottom: 40px;
            }
        }

        a {
            color: $white;
        }
    }

    .footer--copyright {
        border-top: 1px solid rgba($white, 0.1);
        border-bottom: 1px solid rgba($white, 0.1);
        padding: 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-top: 150px;

        @include media-breakpoint-down(md) {
            padding: 35px 0;
        }

        p {
            font-size: 10px;
            line-height: 15px;
            margin-right: 10px;
        }

        .conecto-copyright {
            background: svg-load('../images/layout/svg/site_by_CONECTO.svg') no-repeat;
            background-size: 100% auto;
            width: 140px;
            height: 10px;
        }

        .footer--icons {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -25px;
        }

        .footer--social--link {
            display: inline-block;
            height: 40px;
            width: 40px;
            background-color: $lighter-violet;
            border-radius: 100%;
            margin: 0 5px;

            i {
                width: 100%;
                height: 100%;
            }
        }
    }

    .footer--badges {
        position: absolute;
        bottom: 100px;
        left: 50%;
        transform: translateX(-50%);
        width: fit-content;

        a {
            margin: 0 15px;
            width: 100px;
            height: 36px;
            display: block;

            @include media-breakpoint-down(xxxl) {
                display: inline-block;
            }
        }

        @include media-breakpoint-down(sm) {
            bottom: 150px;
            min-width: 300px;
            text-align: center;
        }

        @include media-breakpoint-up(xxxl) {
            right: -150px;
            top: 30px;
            left: unset;
            transform: unset;

            a {
                margin: 30px 0;
            }
        }

        .footer--tripadvisor {
            background: url('../images/tripadvisor.png') no-repeat center center;
        }

        .footer--holidaycheck {
            background: url('../images/holidaycheck.png') no-repeat center center;
        }
    }
}