.header-media {
    position: relative;
    //height: calc(100vh - 55px);
    z-index: 1;
    overflow: hidden;
    width: 100vw;
    height: 0;
    padding-bottom: 160vw;

    @include media-breakpoint-up(xl) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0 !important;
        padding-bottom: 44%;
    }

    @include media-breakpoint-between(md, xl) {
        padding-bottom: 80vw;
    }

    @include media-breakpoint-between(xl, xxxl) {
        padding-bottom: 70vw;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        opacity: 0.6;
        z-index: 1;
    }

    > img,
    > picture,
    > picture > img,
    > video,
    > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    > img,
    > picture > img,
    > video {
        object-fit: cover;
    }

    > video {
        @include media-breakpoint-between(md, xxxl) {
            bottom: -30vw;
            height: 151%;
            top: -30vw;
            width: 100vw;
        }
        @include media-breakpoint-down(md) {
            height: 126%;
            width: 100vw;
            top: -20vw;
            bottom: -20vw;
        }
    }

    &--content {
        position: relative;
        height: 100%;
        width: 100%;
        z-index: 1;

        .header-text {
            display: none;
            position: absolute;
            color: $white;
            padding-right: 15px;
            max-width: 900px;
            @include fluid-property('left', $viewport-width-min, $viewport-width-max, 15px, 155px);
            @include fluid-property('bottom', $viewport-width-min, $viewport-width-max, 160px, 270px);

            small {
                @include fluid-type($viewport-width-min, $viewport-width-max, 18px, 22px);
                line-height: 32px;
            }
        }
    }

    .logo {
        position: absolute;
        z-index: 1;
        left: 15px;
        top: 15px;

        @include media-breakpoint-up(xl) {
            display: none;
        }
    }
}