.btn {
    display: inline-block;
    padding: $btn-padding;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    font-size: $btn-font-size;
    line-height: $btn-line-height;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: $btn-border-width solid;
    border-radius: $btn-border-radius;
    box-shadow: none;
    transition: background-color .3s, color .3s, border .3s;
    text-transform: uppercase;

    &:hover {
        color: $text-color;
        text-decoration: none;
    }

    &:focus {
        outline: 0;
        box-shadow: none;
    }

    &:active,
    &.active {

    }

    &:disabled,
    &.disabled {
        pointer-events: none;
        opacity: $btn-disabled-opacity;
    }

    &-block {
        display: block;
    }

    // Primary Button
    &-primary {
        @include custom-button-variant($brand-primary, $brand-primary, $white, $lighter-violet, $lighter-violet, $white);
    }

    // Secondary Button
    &-secondary {
        @include custom-button-variant($white, $white, $brand-primary, $lighter-violet, $lighter-violet, $white);
    }

    // Outline Button
    &-outline {
        @include custom-button-variant(transparent, $brand-primary, $brand-primary, $lighter-violet, $lighter-violet, $white);
    }

    // Large Button
    &-lg {
        font-size: $btn-font-size * 1.2;
    }

    // Small Button
    &-sm {
        font-size: $btn-font-size * 0.9;
    }
}