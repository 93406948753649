.main-nav {
    position: fixed;
    left: 0;
    right: 0;
    top: 55px;
    bottom: 0;
    background-color: $white;
    z-index: 3;
    overflow: scroll;
    height: 100vh;
    transform: translateX(100%);
    font-size: 16px;

    @include media-breakpoint-up(xl) {
        transform: unset;
        position: relative;
        background-color: transparent;
        top: 0;
        height: unset;
        overflow: unset;
        //margin-left: auto;
        //margin-right: auto;
        padding-left: $container-padding;
        padding-right: $container-padding;
        //max-width: $container-max-width + ($container-padding * 2);
        //max-width: $container-max-width;
        width: 100%;

        &:before {
            content: '';
            height: 100px;
            width: 100%;
            position: fixed;
            left: 0;
            top: 0;
            background-color: $white;
            z-index: 1;
            max-height: 0;
            transition: max-height 0.3s ease-in-out;
        }
    }

    @include media-breakpoint-between(xl, xxl) {
        //max-width: 800px;
    }

    &--list {
        position: absolute;
        top: 100px;
        width: 100%;

        @include media-breakpoint-up(xl) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            top: 0;
            position: relative;
            max-width: $container-max-width;
            margin-left: auto;
            margin-right: auto;
        }

        @include media-breakpoint-between(xl, xxl) {
            margin-left: 0;
        }
    }

    &--item {
        width: 100%;
        position: relative;
        height: 100%;

        @include media-breakpoint-up(xl) {
            width: unset;

            &:hover {

                .sub-nav {
                    max-height: 800px;
                    opacity: 1;
                    visibility: visible;
                }

                & > a:after {
                    width: 100%;
                }
            }

            & > .menu-arrow {
                display: none;
            }

            .sub-nav {
                left: 50%;
                transform: translateX(-50%);
            }

            &:last-child, &:nth-last-child(2) {

                .menu-arrow {
                    right: 0;
                    left: 1px;
                    transform: translateY(-50%) rotate(225deg);
                }

                .ter-nav {
                    left: unset;
                    right: 100%;
                    transform: translateX(-200px);
                }
            }
        }

        @include media-breakpoint-between(xl, xxxl) {
            &:last-child, &:nth-last-child(2) {

                .sub-nav {
                    left: unset;
                    transform: unset;
                    right: 0;
                }
            }

            &:first-child {
                .sub-nav {
                    left: unset;
                    transform: unset;
                }
            }
        }

        & > a {
            display: block;
            padding: 10px 55px 10px 30px;
            font-weight: 700;
            line-height: 50px;
            text-transform: uppercase;

            @include media-breakpoint-up(xl) {
                color: $white;
                padding: 0;
            }

            &:after {
                content: '';
                position: absolute;
                background-color: $brand-primary;
                bottom: 15px;
                left: 50px;
                height: 2px;
                width: 0;
                transition: width 0.5s;

                @include media-breakpoint-up(xl) {
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 8px;
                }
            }
        }

        a {
            color: $darker-gray;
            @include fluid-property('font-size', 1200px, 1400px, 13px, 16px);

            @include media-breakpoint-up(xl) {
                padding: 0 !important;
            }
        }

        label {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    .logo {
        //position: absolute;
        position: relative;
        display: block;
        z-index: 1;
        //left: 15px;
        //top: 15px;
        width: 100%;
        background-image: url("../images/layout/svg/logo-castello-lila.svg");
        //display: none;

        @include media-breakpoint-down(xl) {
            max-width: 200px;
            left: 50px;
        }
    }

    .header-bar--fixed {
        display: none;
    }

    .menu-arrow {
        height: 8px;
        width: 8px;
        display: block;
        position: absolute;
        right: 55px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        border-top: 1px solid $brand-primary;
        border-right: 1px solid $brand-primary;
        transition: transform 0.3s;

        @include media-breakpoint-up(xl) {
            right: 8px;
        }
    }

    .sub-nav {
        overflow: hidden;
        visibility: hidden;
        max-height: 0;
        opacity: 0;
        background-color: $lighter-gray;
        transition: max-height 0.5s, opacity 0.3s;

        @include media-breakpoint-up(xl)  {
            background-color: $white;
            position: absolute;
            white-space: nowrap;
            overflow: unset;
            padding: 15px 0;
        }

        & > li {
            position: relative;

            @include media-breakpoint-up(xl) {
                position: unset;
                padding: 0 25px;

                &:hover {
                    background-color: $lighter-gray;

                    .ter-nav {
                        max-height: 800px;
                        opacity: 1;
                        visibility: visible;
                        transform: translateX(0) !important;
                    }
                }
            }

            &:first-child > a {
                padding: 20px 55px 10px 70px;
            }

            &:last-child, &:nth-last-child(2) {

                & > a {
                    padding: 10px 55px 20px 70px;
                }
            }

            & > a {
                padding: 10px 55px 10px 70px;
                display: block;
                position: relative;
                font-weight: 400;
                line-height: 25px;
                text-transform: uppercase;

                @include media-breakpoint-up(xl) {
                    padding: 12px 20px !important;

                    &:hover {
                        background-color: $lighter-gray;
                    }
                }
            }
        }
    }

    .ter-nav {
        overflow: hidden;
        visibility: hidden;
        max-height: 0;
        opacity: 0;
        background-color: $lighter-gray;
        transition: max-height 0.5s, opacity 0.3s;

        @include media-breakpoint-up(xl) {
            position: absolute;
            left: calc(100% - 1px);
            height: 100%;
            top: 0;
            transform: translateX(200px);
            transition: transform 0.3s ease-out, opacity 0.3s;
            padding: 15px 0;
            white-space: nowrap;
            max-width: 500px;
        }

        li {

            @include media-breakpoint-up(xl) {
                padding: 0 25px;
            }
        }

        a {
            padding: 10px 55px 10px 100px;
            display: block;
            border: 1px solid transparent;

            @include media-breakpoint-up(xl) {
                padding: 12px 20px !important;

                &:hover {
                    border-color: $black;
                    border-radius: 5px;
                }
            }
        }

        li:last-child a {
            padding: 10px 55px 30px 100px;
        }
    }

    &--toggle {
        height: 50px;
        width: 50px;
        top: 15px;
        right: 15px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;

        @include media-breakpoint-up(xl) {
            display: none;
        }

        .hamburger-box {
            height: 22px;
            width: 35px;
            position: relative;

            &--inner {
                background-color: $white;
                width: 26px;
                height: 2px;
                display: block;
                z-index: 1;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                border-radius: 2px;

                &:before, &:after {
                    content: '';
                    width: 35px;
                    height: 2px;
                    background-color: $white;
                    display: block;
                    position: absolute;
                    left: -9px;
                    transition: transform 0.3s;
                    border-radius: 2px;
                }

                &:before {
                    top: -10px;
                }

                &:after {
                    bottom: -10px;
                }
            }
        }
    }

    @include media-breakpoint-between(sm, xl) {
        left: 50% !important;
    }

    @include media-breakpoint-up(xl) {

        label {
            display: none;
        }

        .logo {
            opacity: 0;
            transform: translateX(-200px);
            visibility: hidden;
            transition: opacity 0.3s, transform 0.3s;
        }
    }
}

input[name="toggleMobileNav"], input[name="toggleSubNav"], input[name="toggleTerNav"] {
    display: none;
}

@include media-breakpoint-down(xl) {
    input[name="toggleMobileNav"] {

        &:checked {

            & ~ .page-wrap {

                .header-bar {
                    position: fixed;
                    top: 0;
                }

                .header-media {
                    top: 55px;

                    &--content {
                        label {
                            display: none;
                        }
                    }
                }

                .main-nav {
                    transform: translateX(0);
                    transition: transform 0.3s ease-in-out;

                    &--list {
                        padding-bottom: 90px;
                    }

                    &--toggle {
                        position: fixed;
                        top: 15px;
                        background-image: url('../images/layout/svg/clos-btn.svg');
                    }
                }

                .hamburger-box {

                    &--inner {
                        background-color: transparent;

                        &:before, &:after {
                            transition: transform 0.3s;
                            background-color: $black;
                        }

                        &:before {
                            transform: rotate(45deg);
                            top: 0;
                        }

                        &:after {
                            transform: rotate(-45deg);
                            bottom: 0;
                        }
                    }
                }
            }
        }
    }

    input[name="toggleSubNav"] {

        &:checked {

            & ~ label {
                display: none;
            }

            & ~ a {
                background-color: $lighter-gray;
                position: relative;

                &:after {
                    width: 75px;
                }
            }

            & ~ .menu-arrow {
                transform: rotate(135deg);
                top: 40px;
            }

            & ~ .sub-nav {
                max-height: 100vh;
                opacity: 1;
                visibility: visible;
            }
        }
    }

    input[name="toggleTerNav"] {

        &:checked {

            & ~ a {

                label {
                    display: none;
                }

                .menu-arrow {
                    transform: rotate(135deg);
                    top: 25px;
                }
            }

            & ~ .ter-nav {
                max-height: 100vh;
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.main-nav--fixed {
    .header-bar {
        @include media-breakpoint-between(xl, xxxl) {
            right: 0;

            &--meta {
                align-items: center;
                margin-right: 20px;
                position: relative;
                //top: 80px;
                opacity: 0;
                transform: translateX(100%);
                transition: transform 0.5s;

                &.show {
                    opacity: 1;
                    transform: translateX(0);
                }

                .lang-nav li {
                    display: inline-block;
                    margin: 0 0 0 10px;
                }

                & > a {
                    margin: 0 0 0 10px;
                }

            }
        }
    }

    .main-nav {
        @include media-breakpoint-up(xl) {
            display: grid;
            align-items: center;
            grid-column-gap: 40px;
            grid-template-columns: 250px auto auto;
        }
    }

    @include media-breakpoint-between(xl, xxl) {
        grid-template-columns: 200px auto auto;
    }

    @include media-breakpoint-up(xl) {
        .main-nav {
            z-index: 3;

            @include media-breakpoint-up(xl) {
                position: fixed;
                height: 100px;
            }

            & > .logo {
                opacity: 1;
                transform: translateX(0);
                visibility: visible;
                //left: 70px;
                //top: 10px;
                display: block;
            }

            &:before {
                max-height: 100px;
            }

            &--list {
                z-index: 2;
            }

            &--item {
                height: unset;
                & > a {
                    color: $black;
                }
            }
        }

        .header-bar {
            position: fixed;
            top: 30px;
            right: 70px;
            height: unset;
            width: unset;
            margin: 0;
            padding: 0;
            transition: all 0.3s ease-in-out;

            i {
                filter: brightness(0) saturate(100%);
            }

            a {
                color: $black !important;
                background: svg-load('../images/layout/svg/btn-border-black.svg') no-repeat;

                &:hover {
                    color: $white !important;
                }
            }
        }

        .header-bar--fixed {
            display: block;
        }
    }
}