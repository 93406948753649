.area--text {
    word-break: break-word;

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        margin-bottom: .5em;
    }

    a {
        font-weight: $font-weight-bold;
    }

    li {
        line-height: 34px ;
        padding-left: 15px;
        position: relative;

        &:before {
            content: '';
            height: 6px;
            width: 6px;
            border-top: 1px solid $brand-primary;
            border-right: 1px solid $brand-primary;
            position: absolute;
            left: 0;
            top: 1rem;
            transform: translateY(-50%) rotate(45deg);
        }
    }
}