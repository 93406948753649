@font-face {
	font-family: 'Meow Script';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	font-stretch: normal;
	src: url(../fonts/googlefonts/Meow_Script-normal-400.woff2) format('woff2'), url(../fonts/googlefonts/Meow_Script-normal-400.woff) format('woff'), url(../fonts/googlefonts/Meow_Script-normal-400.ttf) format('truetype');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	font-stretch: normal;
	src: url(../fonts/googlefonts/Raleway-normal-400.woff2) format('woff2'), url(../fonts/googlefonts/Raleway-normal-400.woff) format('woff'), url(../fonts/googlefonts/Raleway-normal-400.ttf) format('truetype');
	unicode-range: U+0-10FFFF;
}

