.header-booking {
    color: $white;
    position: relative;
    top: -40px;
    //bottom: 0;
    z-index: 1;
    max-width: 910px;
    //padding: 0 30px;
    margin-right: 140px;
    margin-left: auto;

    @include media-breakpoint-down(xxxl) {
        margin-right: auto;
        bottom: unset;
        top: 0;
    }

    @include media-breakpoint-down(lg) {
        padding: 0 30px;
    }

    &--inner {
        display: flex;
        flex-wrap: wrap;
        padding: 30px 40px 50px 40px;
        background-color: $brand-primary;
        max-width: 910px;
        transition: transform 0.5s ease-in-out;

        .booking-hide-button {
            display: none;
        }

        &.hide-booking {
            transition: transform 0.5s ease-in-out;

            .booking-hide-button {
                height: 70px;
                width: 70px;
                position: absolute;
                left: -70px;
                top: 50%;
                transform: translateY(-50%);
                background-color: $brand-primary;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }

        &.clicked, &.hide-booking {
            position: fixed;
            right: 0;
            top: 190px;
        }

        &.clicked {
            .booking-hide-button {
                display: none;
            }
        }
    }

    .header-booking--datepicker {
        position: relative;

        &--day {
            @include fluid-type($viewport-width-min, $viewport-width-max, 40px, 50px);
            line-height: 1.25;
            font-weight: $font-weight-bold;
        }

        &--month {
            font-size: 18px;
            line-height: 32px;
        }

        input {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            background: transparent;
            border: transparent;
            color: transparent;
        }
    }

    .numberpicker {

        input {
            background: none;
            border: none;
            font-size: 40px;
            font-weight: $font-weight-bold;
            color: $white;
            max-width: 3rem;
            text-align: center;
            padding: 0 !important;
        }

        button {
            background: none;
            border: none;
            width: 20px;
            height: 20px;
            position: relative;
            padding: 0;
            top: 50%;
            transform: translateY(-50%);

            &.minus:before {
                content: '';
                height: 2px;
                width: 100%;
                background-color: $white;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            &.plus {

                &:before {
                    content: '';
                    height: 2px;
                    width: 100%;
                    background-color: $white;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:after {
                    content: '';
                    height: 100%;
                    width: 2px;
                    background-color: $white;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }

    i {
        fill: $white;
        height: 2em;
        width: 2em;
    }

    .col {
        padding: 10px 10px;
        flex: 1 0 auto;

        &:last-child {
            padding: 20px 0 0 0;
            flex: 1 0 100%;
        }

        &:not(:last-child) {
            border-bottom: 1px solid #A84D90;
        }

        .people {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            @include media-breakpoint-down(xs) {
                flex-direction: column;
            }

            & > .form-element {
                padding: 0 5px;
            }
        }

        .btn {
            display: block;
            width: 100%;
            margin-bottom: 1rem;

            @include media-breakpoint-up(lg) {
                display: inline-block;
                width: 49%;
            }

            &-outline {
                border-color: $white;
                color: $white;

                &:hover {
                    border-color: $lighter-violet;
                }
            }
        }
    }
}