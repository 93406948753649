.page-header {
    position: relative;
    display: block;
    height: 100%;

    @include media-breakpoint-up(xl) {
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 40px 135px 50px auto;
        grid-template-areas:
        "   meta-bar    "
        "   logo        "
        "   navigation  "
        "   content     ";


        & > .header-logo {
            z-index: 2;

            .logo {
                display: block;
                width: 400px;
                height: 100%;
                margin: 0 auto;
            }
        }
    }

    @include media-breakpoint-between(xl, xxxl) {
        height: 72vw !important;
    }

    & > .logo {
        display: none;
    }

    &--content {
        grid-area: content;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        z-index: 1;

        @include media-breakpoint-down(xl) {
            height: 0;
            padding-top: 107%;
            @include fluid-property('padding-top', 375px, 1999px, 400px, 10px)
        }

        h1 {
            z-index: 1;
            align-self: flex-start;
            color: $white;
            padding-left: 150px;
            margin-bottom: 1rem;

            p {
                font-size: inherit;
                margin-bottom: 0;
            }

            @include media-breakpoint-down(xl) {
                padding-left: 15px;
                padding-right: 15px;
            }

            small {
                color: $white;
            }
        }
    }

    .header-badge {
        height: 110px;
        width: 110px;
        position: absolute;
        top: 190px;
        right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transform: rotate(15deg);
        background: svg-load('../images/layout/svg/badge-header.svg');
        background-size: cover;
        z-index: 2;

        @include media-breakpoint-between(xl, xxxl) {
            top: 70px;
        }

        @include media-breakpoint-up(md) {
            right: 60px;
            height: 180px;
            width: 180px;

            span,
            .link-text {
                font-size: 12px;
            }
        }

        @include media-breakpoint-up(xl) {
            top: 220px;
        }

        h5 {
            text-align: center;
            color: $white;
        }

        a {
            text-align: center;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 50%;

            .pimcore_editable_link {
                a.pimcore_open_link_button,
                a.pimcore_edit_link_button {
                    border-color: transparent;
                    background: transparent;
                }
            }
        }

        span,
        .link-text {
            font-size: 8px;
            text-transform: uppercase;
            color: $white;
            text-decoration: underline;
            margin-top: 0.5rem;
        }
    }
}

// Styling Anfrageseite
.page-header--small {
    .page-header--content {
        padding-top: 0;
    }

    @include media-breakpoint-up(xl) {
        height: 40vw;
    }
}

// Styling editmode
.page-header.page-header--editmode {
    height: 44vw;
    .page-header--content {
        justify-content: center;
    }
}