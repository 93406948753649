/*** IMPORT CONFIG ***/
/** Icon path is only for Pimcore Icon Utility
$icon-path: /static/images/icons/
*/
/*** IMPORT VENDOR ***/
/*!
 * baguetteBox.js
 * @author  feimosi
 * @version %%INJECT_VERSION%%
 * @url https://github.com/feimosi/baguetteBox.js
 */
#baguetteBox-overlay {
  display: none;
  opacity: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: #222;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity 0.5s ease;
}
#baguetteBox-overlay.visible {
  opacity: 1;
}
#baguetteBox-overlay .full-image {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}
#baguetteBox-overlay .full-image figure {
  display: inline;
  margin: 0;
  height: 100%;
}
#baguetteBox-overlay .full-image img {
  display: inline-block;
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
#baguetteBox-overlay .full-image figcaption {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  line-height: 1.8;
  white-space: normal;
  color: #ccc;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6);
  font-family: sans-serif;
}
#baguetteBox-overlay .full-image:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}

#baguetteBox-slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  transition: left 0.4s ease, transform 0.4s ease;
}
#baguetteBox-slider.bounce-from-right {
  animation: bounceFromRight 0.4s ease-out;
}
#baguetteBox-slider.bounce-from-left {
  animation: bounceFromLeft 0.4s ease-out;
}

@keyframes bounceFromRight {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -30px;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes bounceFromLeft {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 30px;
  }
  100% {
    margin-left: 0;
  }
}
.baguetteBox-button#previous-button, .baguetteBox-button#next-button {
  top: 50%;
  top: calc(50% - 30px);
  width: 44px;
  height: 60px;
}

.baguetteBox-button {
  position: absolute;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 15%;
  background-color: #323232;
  background-color: rgba(50, 50, 50, 0.5);
  color: #ddd;
  font: 1.6em sans-serif;
  transition: background-color 0.4s ease;
}
.baguetteBox-button:focus, .baguetteBox-button:hover {
  background-color: rgba(50, 50, 50, 0.9);
}
.baguetteBox-button#next-button {
  right: 2%;
}
.baguetteBox-button#previous-button {
  left: 2%;
}
.baguetteBox-button#close-button {
  top: 20px;
  right: 2%;
  right: calc(2% + 6px);
  width: 30px;
  height: 30px;
}
.baguetteBox-button svg {
  position: absolute;
  left: 0;
  top: 0;
}

/*
    Preloader
    Borrowed from http://tobiasahlin.com/spinkit/
*/
.baguetteBox-spinner {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}

.baguetteBox-double-bounce1,
.baguetteBox-double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: bounce 2s infinite ease-in-out;
}

.baguetteBox-double-bounce2 {
  animation-delay: -1s;
}

@keyframes bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  left: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  right: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0 ;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7;
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
/**
 * IMPORT FONTS
 * ------------
 * insert fonts in .build/.fonts
 * execute gulp fonts
 */
@font-face {
  font-family: "Meow Script";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  font-stretch: normal;
  src: url(../fonts/googlefonts/Meow_Script-normal-400.woff2) format("woff2"), url(../fonts/googlefonts/Meow_Script-normal-400.woff) format("woff"), url(../fonts/googlefonts/Meow_Script-normal-400.ttf) format("truetype");
  unicode-range: U+0-10FFFF;
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  font-stretch: normal;
  src: url(../fonts/googlefonts/Raleway-normal-400.woff2) format("woff2"), url(../fonts/googlefonts/Raleway-normal-400.woff) format("woff"), url(../fonts/googlefonts/Raleway-normal-400.ttf) format("truetype");
  unicode-range: U+0-10FFFF;
}
/* meow-script-regular - latin */
@font-face {
  font-family: "Meow Script";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/googlefonts/meow-script-v3-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/googlefonts/meow-script-v3-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/googlefonts/meow-script-v3-latin-regular.woff2") format("woff2"), url("../fonts/googlefonts/meow-script-v3-latin-regular.woff") format("woff"), url("../fonts/googlefonts/meow-script-v3-latin-regular.ttf") format("truetype"), url("../fonts/googlefonts/meow-script-v3-latin-regular.svg#MeowScript") format("svg");
  /* Legacy iOS */
  font-display: swap;
}
/*** IMPORT BASIC STUFF ***/
/* Fluid for mobile (375px) */
/* Fluid up to xl */
/* Fluid up to xlplus */
/* Fluid up to outer */
/*
  fluid mixin
  - provides a fluid layout for mobile and px layout for everything else
  - one value for both sizes
 */
/*
  fluidDiff mixin
  - provides a fluid layout for mobile and px layout for everything else
  - different values for both sizes
 */
/* Container Mimicry */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border-color: rgba(0, 0, 0, 0.1);
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1em;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 900;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #83227A;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #5b1754;
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #000000;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin: 0;
}

button {
  border-radius: 0;
}

button:focus {
  /*outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;*/
  outline: none;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.embed-responsive {
  position: relative;
  width: 100%;
  height: 0;
}
.embed-responsive-1by1 {
  padding-bottom: 100%;
}
.embed-responsive-3by2 {
  padding-bottom: 66.66666%;
}
.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}
.embed-responsive > * {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.embed-responsive > iframe {
  border: none;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  line-height: 1;
  font-family: "Meow Script", cursive;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  word-break: break-all;
  color: #83227A;
  hyphens: auto;
  word-break: break-all;
}
@supports (hyphens: auto) {
  h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    word-break: normal;
  }
}
@media (min-width: 992px) {
  h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    word-break: normal;
  }
}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
.h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a {
  color: inherit;
  text-decoration: none;
}
h1 a:hover, h1 a:focus, h2 a:hover, h2 a:focus, h3 a:hover, h3 a:focus, h4 a:hover, h4 a:focus, h5 a:hover, h5 a:focus, h6 a:hover, h6 a:focus,
.h1 a:hover,
.h1 a:focus, .h2 a:hover, .h2 a:focus, .h3 a:hover, .h3 a:focus, .h4 a:hover, .h4 a:focus, .h5 a:hover, .h5 a:focus, .h6 a:hover, .h6 a:focus {
  color: inherit;
  text-decoration: none;
}
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small,
.h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small {
  display: block;
  margin-top: 0.25em;
  line-height: 32px;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  word-break: normal;
  color: #000;
}
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small,
.h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small {
  font-size: clamp(18px, calc(18px + 4 * ((100vw - 768px) / 432)), 22px);
}

h1, .h1 {
  font-size: clamp(60px, calc(60px + 70 * ((100vw - 768px) / 432)), 130px);
}

h2, .h2 {
  font-size: clamp(50px, calc(50px + 40 * ((100vw - 768px) / 432)), 90px);
}

h3, .h3 {
  font-size: clamp(40px, calc(40px + 30 * ((100vw - 768px) / 432)), 70px);
}
@media (max-width: 767.98px) {
  h3 small, .h3 small {
    font-size: 18px;
  }
}

h4, .h4 {
  font-size: clamp(30px, calc(30px + 20 * ((100vw - 768px) / 432)), 50px);
}
h4 small, .h4 small {
  font-size: 0.8em;
}
@media (max-width: 767.98px) {
  h4 small, .h4 small {
    font-size: 18px;
  }
}

h5, .h5 {
  font-size: clamp(20px, calc(20px + 15 * ((100vw - 768px) / 432)), 35px);
}
h5 small, .h5 small {
  font-size: 1em;
}

h6, .h6 {
  font-size: 18px;
}
h6 small, .h6 small {
  font-size: 1em;
}

hr {
  color: #000;
  margin-top: 1.6666666667rem;
  margin-bottom: 1.6666666667rem;
  opacity: 1;
}
@media (min-width: 992px) {
  hr {
    margin-top: 3.3333333333rem;
    margin-bottom: 3.3333333333rem;
  }
}

p {
  margin-bottom: 1em;
}
p {
  font-size: clamp(16px, calc(16px + 2 * ((100vw - 768px) / 432)), 18px);
}

.lead {
  margin-bottom: 1.2em;
  font-weight: 900;
}
.lead {
  font-size: clamp(18px, calc(18px + 2 * ((100vw - 768px) / 432)), 20px);
}

.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-justify {
  text-align: justify !important;
}

/*** IMPORT LAYOUT ***/
html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  width: 100%;
}
@media (max-width: 767.98px) {
  .offcanvas-nav--visible body {
    overflow-y: hidden;
  }
}

::selection {
  background: #83227A;
  color: #fff;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.page-wrap {
  overflow-x: hidden;
}

.page-header {
  position: relative;
  display: block;
  height: 100%;
}
@media (min-width: 1200px) {
  .page-header {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 40px 135px 50px auto;
    grid-template-areas: "   meta-bar    " "   logo        " "   navigation  " "   content     ";
  }
  .page-header > .header-logo {
    z-index: 2;
  }
  .page-header > .header-logo .logo {
    display: block;
    width: 400px;
    height: 100%;
    margin: 0 auto;
  }
}
@media (min-width: 1200px) and (max-width: 1599.98px) {
  .page-header {
    height: 72vw !important;
  }
}
.page-header > .logo {
  display: none;
}
.page-header--content {
  grid-area: content;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .page-header--content {
    height: 0;
    padding-top: 107%;
  }
  .page-header--content {
    padding-top: clamp(400px, calc(400px + -390 * ((100vw - 375px) / 1624)), 10px);
  }
}
.page-header--content h1 {
  z-index: 1;
  align-self: flex-start;
  color: #fff;
  padding-left: 150px;
  margin-bottom: 1rem;
}
.page-header--content h1 p {
  font-size: inherit;
  margin-bottom: 0;
}
@media (max-width: 1199.98px) {
  .page-header--content h1 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.page-header--content h1 small {
  color: #fff;
}
.page-header .header-badge {
  height: 110px;
  width: 110px;
  position: absolute;
  top: 190px;
  right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: rotate(15deg);
  background: svg-load("../images/layout/svg/badge-header.svg");
  background-size: cover;
  z-index: 2;
}
@media (min-width: 1200px) and (max-width: 1599.98px) {
  .page-header .header-badge {
    top: 70px;
  }
}
@media (min-width: 768px) {
  .page-header .header-badge {
    right: 60px;
    height: 180px;
    width: 180px;
  }
  .page-header .header-badge span,
.page-header .header-badge .link-text {
    font-size: 12px;
  }
}
@media (min-width: 1200px) {
  .page-header .header-badge {
    top: 220px;
  }
}
.page-header .header-badge h5 {
  text-align: center;
  color: #fff;
}
.page-header .header-badge a {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
}
.page-header .header-badge a .pimcore_editable_link a.pimcore_open_link_button,
.page-header .header-badge a .pimcore_editable_link a.pimcore_edit_link_button {
  border-color: transparent;
  background: transparent;
}
.page-header .header-badge span,
.page-header .header-badge .link-text {
  font-size: 8px;
  text-transform: uppercase;
  color: #fff;
  text-decoration: underline;
  margin-top: 0.5rem;
}

.page-header--small .page-header--content {
  padding-top: 0;
}
@media (min-width: 1200px) {
  .page-header--small {
    height: 40vw;
  }
}

.page-header.page-header--editmode {
  height: 44vw;
}
.page-header.page-header--editmode .page-header--content {
  justify-content: center;
}

.header-bar {
  background-color: #fff;
  z-index: 2;
  width: 100%;
  height: 55px;
  padding: 8px 15px;
}
@media (min-width: 1200px) {
  .header-bar {
    height: 100%;
    background-color: transparent;
    margin-top: 30px;
    z-index: 4;
    padding: 0 70px;
  }
}
.header-bar .btn {
  border-color: #575756;
}
.header-bar--mobile {
  position: fixed;
  height: 55px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  z-index: 2;
  opacity: 0;
  max-height: 0;
  background-color: #fff;
}
@media (min-width: 1200px) {
  .header-bar--mobile {
    display: none;
  }
}
.header-bar--mobile.fixed {
  max-height: 55px;
  opacity: 1;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}
.header-bar--mobile .main-nav--toggle {
  position: relative;
  right: unset;
  top: unset;
}
.header-bar--mobile .main-nav--toggle .hamburger-box--inner {
  background-color: #000;
}
.header-bar--mobile .main-nav--toggle .hamburger-box--inner:before, .header-bar--mobile .main-nav--toggle .hamburger-box--inner:after {
  background-color: #000;
}
.header-bar--mobile .btn {
  align-self: center;
  margin: 0 20px;
  border-color: #575756;
  color: #575756;
}
.header-bar--mobile-content {
  display: flex;
}
.header-bar--mobile .logo {
  height: 100%;
  background-size: contain;
  background-image: url("../images/layout/svg/logo-castello-lila.svg");
  display: block;
  width: 100%;
}
.header-bar--content, .header-bar--meta {
  display: flex;
}
.header-bar--content {
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
@media (min-width: 1200px) {
  .header-bar--content {
    justify-content: flex-end;
  }
}
@media (min-width: 1200px) {
  .header-bar--content .btn {
    display: none;
  }
}
.header-bar--content a:not(.btn) {
  background: svg-load("../images/layout/svg/btn-border.svg") no-repeat;
  border-radius: 100%;
  transition: background-color 0.3s;
}
.header-bar--content a:not(.btn):hover {
  background-color: #83227A;
}
.header-bar--content a:not(.btn):hover i {
  filter: brightness(0) invert(1);
}
.header-bar--meta .icon {
  height: 2em;
  line-height: 2em;
  width: 2em;
  background-size: unset;
}
@media (min-width: 1200px) {
  .header-bar--meta .icon {
    filter: saturate(0) brightness(0) invert(100%) opacity(1);
    height: 40px;
    width: 40px;
  }
}
.header-bar--meta .icon--loupe {
  cursor: pointer;
  filter: brightness(0);
}
@media (min-width: 1200px) {
  .header-bar .header--social--link {
    margin-right: 20px;
  }
}
.header-bar .lang-nav li {
  display: inline-block;
  line-height: 36px;
  width: 32px;
  text-align: center;
}
@media (min-width: 1200px) {
  .header-bar .lang-nav li {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }
  .header-bar .lang-nav li:last-child {
    margin-right: 0;
  }
}
.header-bar .lang-nav li a {
  color: #575756;
  text-transform: uppercase;
}
@media (min-width: 1200px) {
  .header-bar .lang-nav li a {
    color: #fff;
    line-height: 40px;
    display: block;
  }
}

.header-media {
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100vw;
  height: 0;
  padding-bottom: 160vw;
}
@media (min-width: 1200px) {
  .header-media {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0 !important;
    padding-bottom: 44%;
  }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .header-media {
    padding-bottom: 80vw;
  }
}
@media (min-width: 1200px) and (max-width: 1599.98px) {
  .header-media {
    padding-bottom: 70vw;
  }
}
.header-media:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.6;
  z-index: 1;
}
.header-media > img,
.header-media > picture,
.header-media > picture > img,
.header-media > video,
.header-media > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.header-media > img,
.header-media > picture > img,
.header-media > video {
  object-fit: cover;
}
@media (min-width: 768px) and (max-width: 1599.98px) {
  .header-media > video {
    bottom: -30vw;
    height: 151%;
    top: -30vw;
    width: 100vw;
  }
}
@media (max-width: 767.98px) {
  .header-media > video {
    height: 126%;
    width: 100vw;
    top: -20vw;
    bottom: -20vw;
  }
}
.header-media--content {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.header-media--content .header-text {
  display: none;
  position: absolute;
  color: #fff;
  padding-right: 15px;
  max-width: 900px;
}
.header-media--content .header-text {
  left: clamp(15px, calc(15px + 140 * ((100vw - 768px) / 432)), 155px);
}
.header-media--content .header-text {
  bottom: clamp(160px, calc(160px + 110 * ((100vw - 768px) / 432)), 270px);
}
.header-media--content .header-text small {
  line-height: 32px;
}
.header-media--content .header-text small {
  font-size: clamp(18px, calc(18px + 4 * ((100vw - 768px) / 432)), 22px);
}
.header-media .logo {
  position: absolute;
  z-index: 1;
  left: 15px;
  top: 15px;
}
@media (min-width: 1200px) {
  .header-media .logo {
    display: none;
  }
}

.main-content {
  padding-top: 60px;
}
@media (min-width: 992px) {
  .main-content {
    padding-top: 100px;
  }
}
:root {
  --scrollbarWidth: 0px;
}

.footer .container {
  position: relative;
}
.footer--main {
  background-color: #83227A;
  color: #fff;
  padding: 70px 0;
  font-size: 14px;
  line-height: 30px;
}
.footer--main p {
  margin-bottom: 0;
}
.footer--main--inner {
  display: flex;
  flex-wrap: wrap;
}
.footer--main-logo {
  flex: 3 0 auto;
  margin-right: 30px;
}
.footer--main-logo .logo {
  display: block;
  max-width: 300px;
  min-width: 250px;
  width: 100%;
  height: 150px;
  background-size: 100% auto;
  filter: saturate(0) brightness(0) invert(100%) opacity(1);
}
.footer--main .footer--adress {
  flex: 2 0 auto;
  margin-right: 20px;
}
.footer--main .footer--adress i {
  height: 3em;
  width: 3em;
}
.footer--main .footer--main-links {
  flex: 1 0 auto;
  display: flex;
  justify-content: space-evenly;
  min-width: 260px;
}
.footer--main .footer--main-links a:not(.x-btn) {
  padding: 10px 5px 10px 15px;
  display: block;
  position: relative;
}
.footer--main .footer--main-links a:not(.x-btn):before {
  content: "";
  height: 7px;
  width: 7px;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.footer--main .footer--main-links ul:first-child {
  margin-right: 10px;
}
@media (max-width: 575.98px) {
  .footer--main .footer--main-links {
    flex-direction: column;
  }
  .footer--main .footer--main-links ul {
    margin-bottom: 40px;
  }
}
@media (max-width: 575.98px) {
  .footer--main .col {
    margin-bottom: 40px;
  }
}
.footer--main a {
  color: #fff;
}
.footer .footer--copyright {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 150px;
}
@media (max-width: 767.98px) {
  .footer .footer--copyright {
    padding: 35px 0;
  }
}
.footer .footer--copyright p {
  font-size: 10px;
  line-height: 15px;
  margin-right: 10px;
}
.footer .footer--copyright .conecto-copyright {
  background: svg-load("../images/layout/svg/site_by_CONECTO.svg") no-repeat;
  background-size: 100% auto;
  width: 140px;
  height: 10px;
}
.footer .footer--copyright .footer--icons {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -25px;
}
.footer .footer--copyright .footer--social--link {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: #A84D90;
  border-radius: 100%;
  margin: 0 5px;
}
.footer .footer--copyright .footer--social--link i {
  width: 100%;
  height: 100%;
}
.footer .footer--badges {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
}
.footer .footer--badges a {
  margin: 0 15px;
  width: 100px;
  height: 36px;
  display: block;
}
@media (max-width: 1599.98px) {
  .footer .footer--badges a {
    display: inline-block;
  }
}
@media (max-width: 575.98px) {
  .footer .footer--badges {
    bottom: 150px;
    min-width: 300px;
    text-align: center;
  }
}
@media (min-width: 1600px) {
  .footer .footer--badges {
    right: -150px;
    top: 30px;
    left: unset;
    transform: unset;
  }
  .footer .footer--badges a {
    margin: 30px 0;
  }
}
.footer .footer--badges .footer--tripadvisor {
  background: url("../images/tripadvisor.png") no-repeat center center;
}
.footer .footer--badges .footer--holidaycheck {
  background: url("../images/holidaycheck.png") no-repeat center center;
}

/*** IMPORT COMPONENTS ***/
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 60px;
  padding-right: 60px;
  max-width: 1240px;
}
@media (max-width: 575.98px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.container--narrow {
  max-width: 960px;
}
.container--full-width {
  max-width: none;
  width: 100%;
}

.logo {
  background-image: url("../images/layout/svg/logo-negative.svg");
  width: 220px;
  height: 80px;
  background-size: 100% auto;
  background-position: left center;
  background-repeat: no-repeat;
}

.btn {
  display: inline-block;
  padding: 0.675em 1em;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid;
  border-radius: 5px;
  box-shadow: none;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
  text-transform: uppercase;
}
.btn:hover {
  color: #000;
  text-decoration: none;
}
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.btn:disabled, .btn.disabled {
  pointer-events: none;
  opacity: 0.4;
}
.btn-block {
  display: block;
}
.btn-primary {
  color: #fff;
  background-color: #83227A;
  border-color: #83227A;
}
.btn-primary:hover {
  color: #fff;
  background-color: #A84D90;
  border-color: #A84D90;
}
.btn-secondary {
  color: #83227A;
  background-color: #fff;
  border-color: #fff;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #A84D90;
  border-color: #A84D90;
}
.btn-outline {
  color: #83227A;
  background-color: transparent;
  border-color: #83227A;
}
.btn-outline:hover {
  color: #fff;
  background-color: #A84D90;
  border-color: #A84D90;
}
.btn-lg {
  font-size: 21.6px;
}
.btn-sm {
  font-size: 16.2px;
}

.main-nav {
  position: fixed;
  left: 0;
  right: 0;
  top: 55px;
  bottom: 0;
  background-color: #fff;
  z-index: 3;
  overflow: scroll;
  height: 100vh;
  transform: translateX(100%);
  font-size: 16px;
}
@media (min-width: 1200px) {
  .main-nav {
    transform: unset;
    position: relative;
    background-color: transparent;
    top: 0;
    height: unset;
    overflow: unset;
    padding-left: 60px;
    padding-right: 60px;
    width: 100%;
  }
  .main-nav:before {
    content: "";
    height: 100px;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #fff;
    z-index: 1;
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
  }
}
.main-nav--list {
  position: absolute;
  top: 100px;
  width: 100%;
}
@media (min-width: 1200px) {
  .main-nav--list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    top: 0;
    position: relative;
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .main-nav--list {
    margin-left: 0;
  }
}
.main-nav--item {
  width: 100%;
  position: relative;
  height: 100%;
}
@media (min-width: 1200px) {
  .main-nav--item {
    width: unset;
  }
  .main-nav--item:hover .sub-nav {
    max-height: 800px;
    opacity: 1;
    visibility: visible;
  }
  .main-nav--item:hover > a:after {
    width: 100%;
  }
  .main-nav--item > .menu-arrow {
    display: none;
  }
  .main-nav--item .sub-nav {
    left: 50%;
    transform: translateX(-50%);
  }
  .main-nav--item:last-child .menu-arrow, .main-nav--item:nth-last-child(2) .menu-arrow {
    right: 0;
    left: 1px;
    transform: translateY(-50%) rotate(225deg);
  }
  .main-nav--item:last-child .ter-nav, .main-nav--item:nth-last-child(2) .ter-nav {
    left: unset;
    right: 100%;
    transform: translateX(-200px);
  }
}
@media (min-width: 1200px) and (max-width: 1599.98px) {
  .main-nav--item:last-child .sub-nav, .main-nav--item:nth-last-child(2) .sub-nav {
    left: unset;
    transform: unset;
    right: 0;
  }
  .main-nav--item:first-child .sub-nav {
    left: unset;
    transform: unset;
  }
}
.main-nav--item > a {
  display: block;
  padding: 10px 55px 10px 30px;
  font-weight: 700;
  line-height: 50px;
  text-transform: uppercase;
}
@media (min-width: 1200px) {
  .main-nav--item > a {
    color: #fff;
    padding: 0;
  }
}
.main-nav--item > a:after {
  content: "";
  position: absolute;
  background-color: #83227A;
  bottom: 15px;
  left: 50px;
  height: 2px;
  width: 0;
  transition: width 0.5s;
}
@media (min-width: 1200px) {
  .main-nav--item > a:after {
    left: 50%;
    transform: translateX(-50%);
    bottom: 8px;
  }
}
.main-nav--item a {
  color: #575756;
}
.main-nav--item a {
  font-size: clamp(13px, calc(13px + 3 * ((100vw - 1200px) / 200)), 16px);
}
@media (min-width: 1200px) {
  .main-nav--item a {
    padding: 0 !important;
  }
}
.main-nav--item label {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.main-nav .logo {
  position: relative;
  display: block;
  z-index: 1;
  width: 100%;
  background-image: url("../images/layout/svg/logo-castello-lila.svg");
}
@media (max-width: 1199.98px) {
  .main-nav .logo {
    max-width: 200px;
    left: 50px;
  }
}
.main-nav .header-bar--fixed {
  display: none;
}
.main-nav .menu-arrow {
  height: 8px;
  width: 8px;
  display: block;
  position: absolute;
  right: 55px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  border-top: 1px solid #83227A;
  border-right: 1px solid #83227A;
  transition: transform 0.3s;
}
@media (min-width: 1200px) {
  .main-nav .menu-arrow {
    right: 8px;
  }
}
.main-nav .sub-nav {
  overflow: hidden;
  visibility: hidden;
  max-height: 0;
  opacity: 0;
  background-color: #F2F2F2;
  transition: max-height 0.5s, opacity 0.3s;
}
@media (min-width: 1200px) {
  .main-nav .sub-nav {
    background-color: #fff;
    position: absolute;
    white-space: nowrap;
    overflow: unset;
    padding: 15px 0;
  }
}
.main-nav .sub-nav > li {
  position: relative;
}
@media (min-width: 1200px) {
  .main-nav .sub-nav > li {
    position: unset;
    padding: 0 25px;
  }
  .main-nav .sub-nav > li:hover {
    background-color: #F2F2F2;
  }
  .main-nav .sub-nav > li:hover .ter-nav {
    max-height: 800px;
    opacity: 1;
    visibility: visible;
    transform: translateX(0) !important;
  }
}
.main-nav .sub-nav > li:first-child > a {
  padding: 20px 55px 10px 70px;
}
.main-nav .sub-nav > li:last-child > a, .main-nav .sub-nav > li:nth-last-child(2) > a {
  padding: 10px 55px 20px 70px;
}
.main-nav .sub-nav > li > a {
  padding: 10px 55px 10px 70px;
  display: block;
  position: relative;
  font-weight: 400;
  line-height: 25px;
  text-transform: uppercase;
}
@media (min-width: 1200px) {
  .main-nav .sub-nav > li > a {
    padding: 12px 20px !important;
  }
  .main-nav .sub-nav > li > a:hover {
    background-color: #F2F2F2;
  }
}
.main-nav .ter-nav {
  overflow: hidden;
  visibility: hidden;
  max-height: 0;
  opacity: 0;
  background-color: #F2F2F2;
  transition: max-height 0.5s, opacity 0.3s;
}
@media (min-width: 1200px) {
  .main-nav .ter-nav {
    position: absolute;
    left: calc(100% - 1px);
    height: 100%;
    top: 0;
    transform: translateX(200px);
    transition: transform 0.3s ease-out, opacity 0.3s;
    padding: 15px 0;
    white-space: nowrap;
    max-width: 500px;
  }
}
@media (min-width: 1200px) {
  .main-nav .ter-nav li {
    padding: 0 25px;
  }
}
.main-nav .ter-nav a {
  padding: 10px 55px 10px 100px;
  display: block;
  border: 1px solid transparent;
}
@media (min-width: 1200px) {
  .main-nav .ter-nav a {
    padding: 12px 20px !important;
  }
  .main-nav .ter-nav a:hover {
    border-color: #000;
    border-radius: 5px;
  }
}
.main-nav .ter-nav li:last-child a {
  padding: 10px 55px 30px 100px;
}
.main-nav--toggle {
  height: 50px;
  width: 50px;
  top: 15px;
  right: 15px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
@media (min-width: 1200px) {
  .main-nav--toggle {
    display: none;
  }
}
.main-nav--toggle .hamburger-box {
  height: 22px;
  width: 35px;
  position: relative;
}
.main-nav--toggle .hamburger-box--inner {
  background-color: #fff;
  width: 26px;
  height: 2px;
  display: block;
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  border-radius: 2px;
}
.main-nav--toggle .hamburger-box--inner:before, .main-nav--toggle .hamburger-box--inner:after {
  content: "";
  width: 35px;
  height: 2px;
  background-color: #fff;
  display: block;
  position: absolute;
  left: -9px;
  transition: transform 0.3s;
  border-radius: 2px;
}
.main-nav--toggle .hamburger-box--inner:before {
  top: -10px;
}
.main-nav--toggle .hamburger-box--inner:after {
  bottom: -10px;
}
@media (min-width: 576px) and (max-width: 1199.98px) {
  .main-nav {
    left: 50% !important;
  }
}
@media (min-width: 1200px) {
  .main-nav label {
    display: none;
  }
  .main-nav .logo {
    opacity: 0;
    transform: translateX(-200px);
    visibility: hidden;
    transition: opacity 0.3s, transform 0.3s;
  }
}

input[name=toggleMobileNav], input[name=toggleSubNav], input[name=toggleTerNav] {
  display: none;
}

@media (max-width: 1199.98px) {
  input[name=toggleMobileNav]:checked ~ .page-wrap .header-bar {
    position: fixed;
    top: 0;
  }
  input[name=toggleMobileNav]:checked ~ .page-wrap .header-media {
    top: 55px;
  }
  input[name=toggleMobileNav]:checked ~ .page-wrap .header-media--content label {
    display: none;
  }
  input[name=toggleMobileNav]:checked ~ .page-wrap .main-nav {
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
  }
  input[name=toggleMobileNav]:checked ~ .page-wrap .main-nav--list {
    padding-bottom: 90px;
  }
  input[name=toggleMobileNav]:checked ~ .page-wrap .main-nav--toggle {
    position: fixed;
    top: 15px;
    background-image: url("../images/layout/svg/clos-btn.svg");
  }
  input[name=toggleMobileNav]:checked ~ .page-wrap .hamburger-box--inner {
    background-color: transparent;
  }
  input[name=toggleMobileNav]:checked ~ .page-wrap .hamburger-box--inner:before, input[name=toggleMobileNav]:checked ~ .page-wrap .hamburger-box--inner:after {
    transition: transform 0.3s;
    background-color: #000;
  }
  input[name=toggleMobileNav]:checked ~ .page-wrap .hamburger-box--inner:before {
    transform: rotate(45deg);
    top: 0;
  }
  input[name=toggleMobileNav]:checked ~ .page-wrap .hamburger-box--inner:after {
    transform: rotate(-45deg);
    bottom: 0;
  }

  input[name=toggleSubNav]:checked ~ label {
    display: none;
  }
  input[name=toggleSubNav]:checked ~ a {
    background-color: #F2F2F2;
    position: relative;
  }
  input[name=toggleSubNav]:checked ~ a:after {
    width: 75px;
  }
  input[name=toggleSubNav]:checked ~ .menu-arrow {
    transform: rotate(135deg);
    top: 40px;
  }
  input[name=toggleSubNav]:checked ~ .sub-nav {
    max-height: 100vh;
    opacity: 1;
    visibility: visible;
  }

  input[name=toggleTerNav]:checked ~ a label {
    display: none;
  }
  input[name=toggleTerNav]:checked ~ a .menu-arrow {
    transform: rotate(135deg);
    top: 25px;
  }
  input[name=toggleTerNav]:checked ~ .ter-nav {
    max-height: 100vh;
    opacity: 1;
    visibility: visible;
  }
}
@media (min-width: 1200px) and (max-width: 1599.98px) {
  .main-nav--fixed .header-bar {
    right: 0;
  }
  .main-nav--fixed .header-bar--meta {
    align-items: center;
    margin-right: 20px;
    position: relative;
    opacity: 0;
    transform: translateX(100%);
    transition: transform 0.5s;
  }
  .main-nav--fixed .header-bar--meta.show {
    opacity: 1;
    transform: translateX(0);
  }
  .main-nav--fixed .header-bar--meta .lang-nav li {
    display: inline-block;
    margin: 0 0 0 10px;
  }
  .main-nav--fixed .header-bar--meta > a {
    margin: 0 0 0 10px;
  }
}
@media (min-width: 1200px) {
  .main-nav--fixed .main-nav {
    display: grid;
    align-items: center;
    grid-column-gap: 40px;
    grid-template-columns: 250px auto auto;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .main-nav--fixed {
    grid-template-columns: 200px auto auto;
  }
}
@media (min-width: 1200px) {
  .main-nav--fixed .main-nav {
    z-index: 3;
  }
}
@media (min-width: 1200px) and (min-width: 1200px) {
  .main-nav--fixed .main-nav {
    position: fixed;
    height: 100px;
  }
}
@media (min-width: 1200px) {
  .main-nav--fixed .main-nav > .logo {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
    display: block;
  }
}
@media (min-width: 1200px) {
  .main-nav--fixed .main-nav:before {
    max-height: 100px;
  }
}
@media (min-width: 1200px) {
  .main-nav--fixed .main-nav--list {
    z-index: 2;
  }
}
@media (min-width: 1200px) {
  .main-nav--fixed .main-nav--item {
    height: unset;
  }
  .main-nav--fixed .main-nav--item > a {
    color: #000;
  }
}
@media (min-width: 1200px) {
  .main-nav--fixed .header-bar {
    position: fixed;
    top: 30px;
    right: 70px;
    height: unset;
    width: unset;
    margin: 0;
    padding: 0;
    transition: all 0.3s ease-in-out;
  }
  .main-nav--fixed .header-bar i {
    filter: brightness(0) saturate(100%);
  }
  .main-nav--fixed .header-bar a {
    color: #000 !important;
    background: svg-load("../images/layout/svg/btn-border-black.svg") no-repeat;
  }
  .main-nav--fixed .header-bar a:hover {
    color: #fff !important;
  }
}
@media (min-width: 1200px) {
  .main-nav--fixed .header-bar--fixed {
    display: block;
  }
}

.cols {
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}
@media (max-width: 767.98px) {
  .cols {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }
}
.cols.no-gutter {
  grid-column-gap: 0;
  grid-row-gap: 0;
}
@media (min-width: 576px) {
  .cols--2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .cols--2.ratio--25-75 {
    grid-template-columns: 25% 75%;
  }
  .cols--2.ratio--40-60 {
    grid-template-columns: 40% 60%;
  }
  .cols--2.ratio--60-40 {
    grid-template-columns: 60% 40%;
  }
  .cols--2.ratio--75-25 {
    grid-template-columns: 75% 25%;
  }
}
@media (min-width: 768px) {
  .cols--3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .cols--3.ratio--50-25-25 {
    grid-template-columns: 50% 25% 25%;
  }
  .cols--3.ratio--25-50-25 {
    grid-template-columns: 25% 50% 25%;
  }
  .cols--3.ratio--25-25-50 {
    grid-template-columns: 25% 25% 50%;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .cols--3 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .cols--4 {
    grid-template-columns: repeat(4, 1fr);
  }
  .cols--4.ratio--40-20-20-20 {
    grid-template-columns: 40% 20% 20% 20%;
  }
  .cols--4.ratio--20-40-20-20 {
    grid-template-columns: 20% 40% 20% 20%;
  }
  .cols--4.ratio--20-20-40-20 {
    grid-template-columns: 20% 20% 40% 20%;
  }
  .cols--4.ratio--20-20-20-40 {
    grid-template-columns: 20% 20% 20% 40%;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .cols--4 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1200px) {
  .cols--5 {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .cols--5 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .cols--5 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .cols--5 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1200px) {
  .cols--6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .cols--6 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .cols--6 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .cols--6 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.cols .col--2 {
  grid-column: auto/span 2;
}
.cols .col--3 {
  grid-column: auto/span 3;
}
.cols .col--4 {
  grid-column: auto/span 4;
}
.cols .col--5 {
  grid-column: auto/span 5;
}
.cols .col--6 {
  grid-column: auto/span 6;
}

table.contenttable,
.area--text table {
  width: 100%;
  border: none;
  min-width: 550px;
}
table.contenttable th,
table.contenttable td,
.area--text table th,
.area--text table td {
  padding: 15px 30px;
}
table.contenttable thead tr:first-child th,
.area--text table thead tr:first-child th {
  background-color: #83227A;
  color: #fff;
}
table.contenttable thead tr:not(:first-child) th,
.area--text table thead tr:not(:first-child) th {
  background-color: rgba(0, 0, 0, 0.15);
  color: #000;
}
table.contenttable td,
.area--text table td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
table.contenttable p:last-child,
.area--text table p:last-child {
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.table-responsive--large table {
  min-width: 650px;
}

.icon,
.inline-icon {
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  line-height: 1em;
}

.icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.icon--color--white {
  filter: saturate(0) brightness(0) invert(100%) opacity(1);
}

.icon--calendar {
  background-image: svg-load("calendar.svg");
}

.icon--chevron-right {
  background-image: svg-load("chevron-right.svg");
}

.icon--fb {
  background-image: svg-load("fb.svg");
}

.icon--insta {
  background-image: svg-load("insta.svg");
}

.icon--youtube {
  background-image: svg-load("youtube.svg");
}

.icon--mail {
  background-image: svg-load("mail.svg");
}

.icon--phone {
  background-image: svg-load("phone.svg");
}

.icon--bed {
  background-image: svg-load("bed.svg");
}

.icon--mountains {
  background-image: svg-load("mountains.svg");
}

.icon--forest {
  background-image: svg-load("forest.svg");
}

.icon--waterfall {
  background-image: svg-load("waterfall.svg");
}

.icon--fruit {
  background-image: svg-load("fruit.svg");
}

.icon--house {
  background-image: svg-load("house.svg");
}

.icon--gondola {
  background-image: svg-load("gondola.svg");
}

.icon--ski {
  background-image: svg-load("ski.svg");
}

.icon--snow {
  background-image: svg-load("snow.svg");
}

.icon--flower {
  background-image: svg-load("flower.svg");
}

.icon--teaser-mountain {
  background-image: svg-load("teaser-mountain.svg");
}

.icon--leaf {
  background-image: svg-load("leaf.svg");
}

.icon--loupe {
  background-image: svg-load("loupe.svg");
}

.icon--search {
  background-image: svg-load("search.svg");
}

.form-container fieldset,
form.formbuilder fieldset {
  margin-bottom: 10px;
}
.form-container fieldset:last-child,
form.formbuilder fieldset:last-child {
  margin-bottom: 0;
}
.form-container label,
form.formbuilder label {
  display: block;
  margin-bottom: 5px;
  width: 100%;
  line-height: 1.2;
}
.form-container label.required:after,
form.formbuilder label.required:after {
  content: "*";
}
.form-container input[type=email],
.form-container input[type=text],
.form-container input[type=date],
.form-container input[type=password],
.form-container select,
.form-container textarea,
form.formbuilder input[type=email],
form.formbuilder input[type=text],
form.formbuilder input[type=date],
form.formbuilder input[type=password],
form.formbuilder select,
form.formbuilder textarea {
  padding: 8px 12px;
  width: 100%;
  color: #000;
  background: #fff;
  border: 1px solid #000;
  border-radius: 0;
  line-height: 1.5;
}
.form-container input[type=email]:focus,
.form-container input[type=text]:focus,
.form-container input[type=date]:focus,
.form-container input[type=password]:focus,
.form-container select:focus,
.form-container textarea:focus,
form.formbuilder input[type=email]:focus,
form.formbuilder input[type=text]:focus,
form.formbuilder input[type=date]:focus,
form.formbuilder input[type=password]:focus,
form.formbuilder select:focus,
form.formbuilder textarea:focus {
  color: #000;
  background: #fff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(131, 34, 122, 0.5);
}
.form-container input[type=email][readonly], .form-container input[type=email][disabled],
.form-container input[type=text][readonly],
.form-container input[type=text][disabled],
.form-container input[type=date][readonly],
.form-container input[type=date][disabled],
.form-container input[type=password][readonly],
.form-container input[type=password][disabled],
.form-container select[readonly],
.form-container select[disabled],
.form-container textarea[readonly],
.form-container textarea[disabled],
form.formbuilder input[type=email][readonly],
form.formbuilder input[type=email][disabled],
form.formbuilder input[type=text][readonly],
form.formbuilder input[type=text][disabled],
form.formbuilder input[type=date][readonly],
form.formbuilder input[type=date][disabled],
form.formbuilder input[type=password][readonly],
form.formbuilder input[type=password][disabled],
form.formbuilder select[readonly],
form.formbuilder select[disabled],
form.formbuilder textarea[readonly],
form.formbuilder textarea[disabled] {
  color: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.3);
}
.form-container textarea,
form.formbuilder textarea {
  min-height: 120px;
}
.form-container .checkbox,
.form-container .radio,
form.formbuilder .checkbox,
form.formbuilder .radio {
  position: relative;
  padding-left: 20px;
}
.form-container .checkbox input[type=checkbox],
.form-container .checkbox input[type=radio],
.form-container .radio input[type=checkbox],
.form-container .radio input[type=radio],
form.formbuilder .checkbox input[type=checkbox],
form.formbuilder .checkbox input[type=radio],
form.formbuilder .radio input[type=checkbox],
form.formbuilder .radio input[type=radio] {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
}
.form-container .cols,
form.formbuilder .cols {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 15px;
}
.form-group .cols {
  margin-bottom: 0;
  grid-column-gap: 30px;
}
.form-group.has-datepicker {
  position: relative;
}
.form-group.has-datepicker:after {
  display: block;
  content: "";
  position: absolute;
  right: 8px;
  bottom: 8px;
  width: 25px;
  height: 25px;
  background-image: svg-load("calendar-alt.svg", fill="");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.cols .form-group {
  margin-bottom: 0;
}
.form-group .numberpicker {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-group .numberpicker input {
  background: none;
  border: none;
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  max-width: 3rem;
  text-align: center;
  padding: 0 !important;
}
.form-group .numberpicker button {
  background: none;
  border: none;
  width: 20px;
  height: 20px;
  position: relative;
  padding: 0;
}
.form-group .numberpicker button.minus:before {
  content: "";
  height: 2px;
  width: 100%;
  background-color: #000;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.form-group .numberpicker button.plus:before {
  content: "";
  height: 2px;
  width: 100%;
  background-color: #000;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.form-group .numberpicker button.plus:after {
  content: "";
  height: 100%;
  width: 2px;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

/* Error Message */
.error-message {
  border: 1px solid red;
  background: rgba(255, 0, 0, 0.2);
  padding: 5.3333333333vw;
  text-align: center;
  margin-bottom: 10.6666666667vw;
}
@media (min-width: 768px) {
  .error-message {
    padding: 20px;
  }
}
@media (min-width: 768px) {
  .error-message {
    margin-bottom: 40px;
  }
}

.page-intro h2 {
  margin-bottom: 1em;
}
.page-intro--wrap {
  display: flex;
  flex-wrap: wrap;
}
.page-intro--text {
  flex: 1 0 50%;
  padding: 10px 20px 20px 0;
}
.page-intro--info {
  flex: 1 0 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
@media (max-width: 575.98px) {
  .page-intro--info {
    justify-content: flex-start;
  }
}
.page-intro--info .info-wrapper {
  max-width: 250px;
  padding: 10px;
}
.page-intro--info .info-wrapper > p {
  margin-bottom: 2em;
}
.page-intro--info li {
  position: relative;
  padding-left: 50px;
  height: 45px;
  margin-bottom: 2em;
}
.page-intro--info li p {
  margin-bottom: 0;
  height: 45px;
  display: table-cell;
  vertical-align: middle;
}
.page-intro--info li i {
  height: 45px;
  width: 45px;
  line-height: 45px;
  position: absolute;
  left: 0;
}

.header-booking {
  color: #fff;
  position: relative;
  top: -40px;
  z-index: 1;
  max-width: 910px;
  margin-right: 140px;
  margin-left: auto;
}
@media (max-width: 1599.98px) {
  .header-booking {
    margin-right: auto;
    bottom: unset;
    top: 0;
  }
}
@media (max-width: 991.98px) {
  .header-booking {
    padding: 0 30px;
  }
}
.header-booking--inner {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 40px 50px 40px;
  background-color: #83227A;
  max-width: 910px;
  transition: transform 0.5s ease-in-out;
}
.header-booking--inner .booking-hide-button {
  display: none;
}
.header-booking--inner.hide-booking {
  transition: transform 0.5s ease-in-out;
}
.header-booking--inner.hide-booking .booking-hide-button {
  height: 70px;
  width: 70px;
  position: absolute;
  left: -70px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #83227A;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.header-booking--inner.clicked, .header-booking--inner.hide-booking {
  position: fixed;
  right: 0;
  top: 190px;
}
.header-booking--inner.clicked .booking-hide-button {
  display: none;
}
.header-booking .header-booking--datepicker {
  position: relative;
}
.header-booking .header-booking--datepicker--day {
  line-height: 1.25;
  font-weight: 700;
}
.header-booking .header-booking--datepicker--day {
  font-size: clamp(40px, calc(40px + 10 * ((100vw - 768px) / 432)), 50px);
}
.header-booking .header-booking--datepicker--month {
  font-size: 18px;
  line-height: 32px;
}
.header-booking .header-booking--datepicker input {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  background: transparent;
  border: transparent;
  color: transparent;
}
.header-booking .numberpicker input {
  background: none;
  border: none;
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  max-width: 3rem;
  text-align: center;
  padding: 0 !important;
}
.header-booking .numberpicker button {
  background: none;
  border: none;
  width: 20px;
  height: 20px;
  position: relative;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
}
.header-booking .numberpicker button.minus:before {
  content: "";
  height: 2px;
  width: 100%;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.header-booking .numberpicker button.plus:before {
  content: "";
  height: 2px;
  width: 100%;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.header-booking .numberpicker button.plus:after {
  content: "";
  height: 100%;
  width: 2px;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.header-booking i {
  fill: #fff;
  height: 2em;
  width: 2em;
}
.header-booking .col {
  padding: 10px 10px;
  flex: 1 0 auto;
}
.header-booking .col:last-child {
  padding: 20px 0 0 0;
  flex: 1 0 100%;
}
.header-booking .col:not(:last-child) {
  border-bottom: 1px solid #A84D90;
}
.header-booking .col .people {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 369.98px) {
  .header-booking .col .people {
    flex-direction: column;
  }
}
.header-booking .col .people > .form-element {
  padding: 0 5px;
}
.header-booking .col .btn {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .header-booking .col .btn {
    display: inline-block;
    width: 49%;
  }
}
.header-booking .col .btn-outline {
  border-color: #fff;
  color: #fff;
}
.header-booking .col .btn-outline:hover {
  border-color: #A84D90;
}

.search-overlay {
  position: fixed;
  z-index: 9;
  background-color: rgba(242, 242, 242, 0.9);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  max-height: 0;
}
.search-overlay.search-overlay--focus {
  opacity: 1;
  max-height: 1500px;
  transition: opacity 0.3s cubic-bezier(0.7, 0, 0.9, 1);
}
.search-overlay--content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.search-overlay--fieldset {
  width: 80vw;
  display: grid;
  grid-template-columns: 5fr 1fr;
  max-width: 42rem;
}
.search-overlay .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/*** Variables ***/
/*** Styles ***/
.search {
  color: #333333;
}
.search--form {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  margin-bottom: 50px;
}
.search--form form {
  display: flex;
  flex-wrap: nowrap;
}
@media (max-width: 575px) {
  .search--form form {
    flex-wrap: wrap;
    width: 100%;
  }
}
.search--form input[type=text] {
  margin: 0 5px;
  padding: 0 15px;
  height: 40px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
@media (max-width: 575px) {
  .search--form input[type=text] {
    margin: 0 0 10px 0;
    width: 100%;
  }
}
.search--form button[type=submit] {
  margin: 0 5px;
  padding: 12px;
  width: auto;
}
@media (max-width: 575px) {
  .search--form button[type=submit] {
    margin: 0;
    width: 100%;
  }
}
.search--form button[type=submit] .search--icon {
  margin: 0;
}
.search--results--count {
  margin-bottom: 50px;
  text-align: center;
}
.search--result header h3 {
  margin-bottom: 0.5em;
}
.search--result p {
  margin-bottom: 1em;
}
.search--result:last-child {
  margin-top: clamp(30px, calc(30px + 30 * ((100vw - 768px) / 432)), 60px);
}
.search--result:last-child {
  margin-bottom: clamp(30px, calc(30px + 30 * ((100vw - 768px) / 432)), 60px);
}
.search--pagination {
  margin: 50px 0;
  padding: 0;
  list-style-type: none;
  text-align: center;
}
.search--pagination li {
  display: inline-block;
  margin-bottom: 4px;
}
.search--pagination li.current a, .search--pagination li.current a:hover {
  background: #e4e4e4;
}
.search--pagination li a {
  display: block;
  width: 50px;
  height: 50px;
  border: 2px solid #e4e4e4;
  border-radius: 100%;
  color: #333333;
  line-height: 46px;
  font-size: 17px;
  transition: background-color 0.3s;
}
.search--pagination li a:hover {
  background: rgba(228, 228, 228, 0.3);
}
.search--btn {
  display: inline-block;
  padding: 12px 25px;
  width: 100%;
  line-height: 1.3;
  background: #83227A;
  color: #fff;
  border: none;
  border-radius: 40px;
  text-transform: uppercase;
  font-size: 13px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}
.search--btn:hover {
  color: #fff;
  background-color: #333333;
}
.search--btn .search--icon {
  margin: 0 4px 0 -8px;
  filter: saturate(0) brightness(0) invert(100%) opacity(1);
  transition: filter 0.3s;
  font-size: 16px;
  vertical-align: bottom;
}
.search--icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: inherit;
  vertical-align: baseline;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
}
.search--icon--search {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 17'%3E%3Cpath d='M6.067 1.545c1.207 0 2.343.47 3.197 1.325a4.49 4.49 0 011.324 3.196c0 1.208-.47 2.343-1.324 3.198a4.491 4.491 0 01-3.197 1.324A4.494 4.494 0 012.87 9.264a4.527 4.527 0 010-6.394 4.49 4.49 0 013.197-1.325zm0-1.545a6.067 6.067 0 10.001 12.133A6.067 6.067 0 006.067 0z'/%3E%3Cpath d='M14.596 15.726l1.13-1.131-4.941-4.941-1.13 1.131 4.941 4.94z'/%3E%3Cpath d='M10.784 9.927l4.669 4.668-.858.858-4.668-4.668.857-.858zm0-.546L9.38 10.786 14.595 16 16 14.596l-5.216-5.215z'/%3E%3C/svg%3E");
}

.searchpage .page-header {
  height: 75vh;
}
.searchpage .header-booking {
  bottom: -70px;
}

iframe.booking {
  height: 100vw;
}

/*** IMPORT AREAS ***/
.area {
  margin-top: clamp(30px, calc(30px + 30 * ((100vw - 768px) / 432)), 60px);
}
.area {
  margin-bottom: clamp(30px, calc(30px + 30 * ((100vw - 768px) / 432)), 60px);
}
.area.margin-top--none {
  margin-top: 0;
}
.area.margin-top--tiny {
  margin-top: 10px;
}
.area.margin-top--small {
  margin-top: 20px;
}
.area.margin-top--medium {
  margin-top: clamp(30px, calc(30px + 30 * ((100vw - 768px) / 432)), 60px);
}
.area.margin-top--large {
  margin-top: clamp(50px, calc(50px + 50 * ((100vw - 768px) / 432)), 100px);
}
.area.margin-top--xlarge {
  margin-top: clamp(65px, calc(65px + 60 * ((100vw - 768px) / 432)), 125px);
}
.area.margin-bottom--none {
  margin-bottom: 0;
}
.area.margin-bottom--tiny {
  margin-bottom: 10px;
}
.area.margin-bottom--small {
  margin-bottom: 20px;
}
.area.margin-bottom--medium {
  margin-bottom: clamp(30px, calc(30px + 30 * ((100vw - 768px) / 432)), 60px);
}
.area.margin-bottom--large {
  margin-bottom: clamp(50px, calc(50px + 50 * ((100vw - 768px) / 432)), 100px);
}
.area.margin-bottom--xlarge {
  margin-bottom: clamp(65px, calc(65px + 60 * ((100vw - 768px) / 432)), 125px);
}
.area > *:first-child {
  margin-top: 0;
}
.area > *:last-child {
  margin-bottom: 0;
}
.area.is-narrow {
  margin-left: auto;
  margin-right: auto;
  max-width: 53.3333333333em;
}
.area.full-width {
  position: relative;
  width: 100vw;
  width: calc(100vw - var(--scrollbarWidth));
  left: 50%;
  transform: translateX(-50%);
}
.area--thumbnail img, .area--thumbnail video {
  display: block;
  width: 100%;
  height: auto;
}

.area--text {
  word-break: break-word;
}
.area--text h1, .area--text h2, .area--text h3, .area--text h4, .area--text h5, .area--text h6,
.area--text .h1, .area--text .h2, .area--text .h3, .area--text .h4, .area--text .h5, .area--text .h6 {
  margin-bottom: 0.5em;
}
.area--text a {
  font-weight: 700;
}
.area--text li {
  line-height: 34px;
  padding-left: 15px;
  position: relative;
}
.area--text li:before {
  content: "";
  height: 6px;
  width: 6px;
  border-top: 1px solid #83227A;
  border-right: 1px solid #83227A;
  position: absolute;
  left: 0;
  top: 1rem;
  transform: translateY(-50%) rotate(45deg);
}

.area--textmedia .cols--2 {
  grid-template-columns: 1.3fr 1fr;
}
@media (max-width: 767.98px) {
  .area--textmedia .cols--2 {
    grid-template-columns: unset;
  }
}
.area--textmedia .leaf:after {
  content: svg-load("../images/layout/svg/leaf.svg");
  position: absolute;
  left: 30px;
  top: -100px;
}
@media (max-width: 767.98px) {
  .area--textmedia .leaf:after {
    content: svg-load("../images/layout/svg/leaf-small.svg");
    top: -60px;
  }
}
.area--textmedia .thumbnail--right.cols--2 {
  grid-template-columns: 1fr 1.3fr;
}
@media (max-width: 767.98px) {
  .area--textmedia .thumbnail--right.cols--2 {
    grid-template-columns: unset;
  }
  .area--textmedia .thumbnail--right.cols--2 .area--textmedia-media {
    order: 1;
  }
  .area--textmedia .thumbnail--right.cols--2 .area--textmedia--text {
    order: 2;
  }
}
.area--textmedia .thumbnail--right .leaf:after {
  left: unset;
  right: 30px;
}
.area--textmedia .thumbnail--left, .area--textmedia .thumbnail--right {
  position: relative;
}
.area--textmedia .area--thumbnail {
  margin: 0;
}
.area--textmedia .area--thumbnail img {
  object-fit: cover;
}
@media (max-width: 575.98px) {
  .area--textmedia .area--thumbnail img {
    height: 400px;
  }
}
.area--textmedia .area--text-headline {
  padding-left: 30px;
}
.area--textmedia .area--text-headline h3 {
  margin-bottom: 0;
}
@media (min-width: 1200px) {
  .area--textmedia .area--text-headline {
    padding-left: 50px;
  }
  .area--textmedia .area--text-headline h3 {
    margin-bottom: 0.5em;
  }
}
.area--textmedia .area--text--inner {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.area--textmedia .area--text--inner .btn-primary {
  margin-right: 1rem;
}
@media (min-width: 768px) {
  .area--textmedia .area--text--inner {
    padding-top: 0;
  }
}
@media (min-width: 1200px) {
  .area--textmedia .area--text--inner {
    padding: 0 25px 100px 50px;
  }
}
.area--textmedia .area--text--inner > *:first-child {
  margin-top: 0;
}
.area--textmedia .area--text--inner > *:last-child {
  margin-bottom: 0;
}
.area--textmedia .area--text-content:before {
  content: "";
  position: absolute;
  right: -100px;
  left: 0;
  bottom: 0;
  background-color: #F2F2F2;
  z-index: -1;
}
.area--textmedia .area--text-content:before {
  top: clamp(40px, calc(40px + 30 * ((100vw - 768px) / 432)), 70px);
}
@media (max-width: 575.98px) {
  .area--textmedia .area--text-content:before {
    top: 240px;
    left: -30px !important;
    right: -30px !important;
  }
}
.area--textmedia-media {
  padding-bottom: 30px;
}
@media (min-width: 1200px) {
  .area--textmedia-media {
    padding-left: 30px;
    padding-bottom: 100px;
  }
}
.area--textmedia .area--thumbnail {
  position: relative;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .area--textmedia .area--thumbnail {
    width: 100%;
    height: 100%;
  }
  .area--textmedia .area--thumbnail:before {
    display: block;
    content: "";
    width: 100%;
    height: 0;
    padding-bottom: 66.6666%;
    background: gray;
  }
  .area--textmedia .area--thumbnail img, .area--textmedia .area--thumbnail video {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-width: none;
  }
}
.area--textmedia .thumbnail--left .area--text-headline {
  padding-left: 30px;
}
@media (min-width: 1200px) {
  .area--textmedia .thumbnail--left .area--text-headline {
    padding-left: 25px;
  }
}
.area--textmedia .thumbnail--left .area--text--inner {
  padding: 30px;
}
@media (min-width: 1200px) {
  .area--textmedia .thumbnail--left .area--text--inner {
    padding: 0 50px 100px 25px;
  }
}
@media (min-width: 1200px) {
  .area--textmedia .thumbnail--left .area--textmedia-media {
    padding-right: 30px;
    padding-left: 0;
  }
}
.area--textmedia .thumbnail--left .area--text-content:before {
  left: -100px;
  right: 0;
}
@media (min-width: 576px) and (max-width: 1199.98px) {
  .area--textmedia.full-width .area--textmedia-media {
    padding: 0 0 50px 60px;
  }
}
@media (min-width: 1200px) {
  .area--textmedia.full-width .area--textmedia-media {
    padding: 0 0 100px 100px;
  }
}
@media (min-width: 1200px) {
  .area--textmedia.full-width .area--textmedia--text {
    padding-right: 100px;
  }
}
@media (min-width: 576px) {
  .area--textmedia.full-width .thumbnail--right .area--text-headline {
    padding-left: 60px;
  }
}
@media (min-width: 1200px) {
  .area--textmedia.full-width .thumbnail--right .area--text-headline {
    padding-left: 100px;
  }
}
@media (min-width: 576px) {
  .area--textmedia.full-width .thumbnail--right .area--text--inner {
    padding: 30px 30px 50px 60px;
  }
}
@media (min-width: 1200px) {
  .area--textmedia.full-width .thumbnail--right .area--text--inner {
    padding: 0 70px 100px 100px !important;
  }
}
@media (min-width: 576px) and (max-width: 1199.98px) {
  .area--textmedia.full-width .thumbnail--right .area--textmedia-media {
    padding: 0 60px 50px !important;
  }
}
@media (min-width: 1200px) {
  .area--textmedia.full-width .thumbnail--right .area--textmedia-media {
    padding: 0 100px 100px 0 !important;
  }
}
@media (min-width: 1200px) {
  .area--textmedia.full-width .thumbnail--right .area--textmedia--text {
    padding-left: 100px;
    padding-right: 0;
  }
}
.area--textmedia.full-width .thumbnail--right .area--text-content:before {
  right: 0;
}
.area--textmedia.full-width .thumbnail--right .area--text-content:before {
  left: clamp(0px, calc(0px + 100 * ((100vw - 768px) / 432)), 100px);
}
.area--textmedia.full-width .area--text-headline {
  padding: 0 30px;
}
@media (min-width: 1200px) {
  .area--textmedia.full-width .area--text-headline {
    padding-left: 70px;
  }
}
.area--textmedia.full-width .area--text--inner {
  padding: 30px;
  padding-bottom: 50px;
}
@media (min-width: 576px) {
  .area--textmedia.full-width .area--text--inner {
    padding: 30px 60px 50px 30px;
  }
}
@media (min-width: 1200px) {
  .area--textmedia.full-width .area--text--inner {
    padding: 0 100px 100px 70px;
  }
}
.area--textmedia.full-width .area--text-content:before {
  left: 0;
}
.area--textmedia.full-width .area--text-content:before {
  right: clamp(0px, calc(0px + 100 * ((100vw - 768px) / 432)), 100px);
}
@media (max-width: 575.98px) {
  .area--textmedia.full-width .area--textmedia-media {
    padding: 0 30px;
  }
}
.area--textmedia.text-center-vertically .area--text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
@media (min-width: 576px) {
  .area--textmedia .cols.thumbnail--right > *:first-child {
    order: 2;
  }
}
@media (min-width: 576px) {
  .area--textmedia .cols.thumbnail--right > *:last-child {
    order: 1;
  }
}

.area--media .area--thumbnail {
  margin: 0;
}

.area--teaser {
  position: relative;
  padding-bottom: 60px;
}
@media (max-width: 991.98px) {
  .area--teaser {
    padding-bottom: 50px;
    padding-top: 50px;
  }
}
.area--teaser.branch:before {
  content: svg-load("../images/layout/svg/branch-big.svg");
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -2;
}
.area--teaser:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  bottom: 0;
  background-color: #F2F2F2;
  z-index: -3;
}
@media (max-width: 991.98px) {
  .area--teaser:after {
    top: 0;
  }
}
.area--teaser .grid-container {
  display: grid;
  grid-template-columns: 0.8fr 1fr 1.2fr 1fr;
  grid-template-rows: 1fr 2fr 0.2fr 1fr;
  gap: 30px 30px;
  grid-auto-flow: row;
  grid-template-areas: ". . teaser-right teaser-right" "teaser-left teaser-left teaser-right teaser-right" "teaser-left teaser-left teaser-bottomRightMiddle teaser-bottomRight" "teaser-bottomLeft teaser-bottomLeftMiddle teaser-bottomRightMiddle teaser-bottomRight" "teaser-bottomLeft teaser-bottomLeftMiddle teaser-bottomRightMiddle teaser-bottomRight";
}
.area--teaser .grid-container {
  height: clamp(850px, calc(850px + 230 * ((100vw - 991px) / 809)), 1080px);
}
@media (max-width: 991.98px) {
  .area--teaser .grid-container {
    height: unset;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 15px 15px;
    grid-template-areas: "teaser-right teaser-right" "teaser-right teaser-right" "teaser-left teaser-left" "teaser-left teaser-left" "teaser-bottomRight teaser-bottomRightMiddle" "teaser-bottomLeft teaser-bottomLeftMiddle";
  }
}
.area--teaser .teaser-bottomLeft {
  grid-area: teaser-bottomLeft;
  background-color: #A84D90;
  transition: background-color 0.3s;
}
.area--teaser .teaser-bottomLeft:hover {
  background-color: #83227A;
}
.area--teaser .teaser-bottomLeftMiddle {
  grid-area: teaser-bottomLeftMiddle;
  background-color: #83227A;
  transition: background-color 0.3s;
}
.area--teaser .teaser-bottomLeftMiddle:hover {
  background-color: #A84D90;
}
.area--teaser .teaser-bottomRight {
  grid-area: teaser-bottomRight;
}
.area--teaser .teaser {
  position: relative;
  cursor: pointer;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
}
.area--teaser .teaser a {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
@media (max-width: 991.98px) {
  .area--teaser .teaser {
    min-height: 175px;
  }
}
@media (min-width: 576px) {
  .area--teaser .teaser:hover .teaser-content {
    max-height: 500px;
    transition: max-height 0.5s ease-in-out;
    margin-bottom: 20px;
  }
  .area--teaser .teaser:hover:before {
    opacity: 0.6;
  }
  .area--teaser .teaser:hover img {
    transform: scale(1.3);
  }
}
.area--teaser .teaser:before {
  content: "";
  position: absolute;
  height: 100%;
  max-height: 900px;
  width: 100%;
  background-color: #000;
  opacity: 0.3;
  z-index: -1;
  transition: opacity 0.4s;
}
.area--teaser .teaser-content {
  max-height: 0;
  height: auto;
  width: 100%;
  transition: max-height 0.5s ease-in-out, margin-bottom 0.5s ease-in-out;
  padding: 0 20px 0px 20px;
  color: #fff;
}
.area--teaser .teaser .area--thumbnail {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -2;
}
.area--teaser .teaser .area--thumbnail img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}
.area--teaser .teaser h3, .area--teaser .teaser h4 {
  color: #fff;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
  width: 100%;
}
.area--teaser .teaser-bottomRightMiddle {
  grid-area: teaser-bottomRightMiddle;
}
.area--teaser .teaser-bottomRightMiddle:before {
  background-color: #83227A;
}
.area--teaser .teaser-right {
  grid-area: teaser-right;
}
.area--teaser .teaser-left {
  grid-area: teaser-left;
}
.area--teaser .teaser-bottomLeft, .area--teaser .teaser-bottomLeftMiddle {
  align-items: center;
  padding-top: 15px;
  overflow: hidden;
}
.area--teaser .teaser-bottomLeft i, .area--teaser .teaser-bottomLeftMiddle i {
  height: 110px;
  width: 110px;
  background-repeat: no-repeat;
  background-position: center center;
}

.area--quote-inner {
  display: flex;
}
.area--quote-inner p {
  font-size: inherit;
  margin-bottom: 0;
}
.area--quote .area--text:first-child {
  margin-right: 15px;
  width: 100%;
}
@media (min-width: 768px) {
  .area--quote .area--text:first-child {
    margin-right: 30px;
  }
}
.area--quote .area--text:nth-child(2) {
  margin-left: 15px;
  width: 100%;
  display: flex;
  align-items: center;
}
.area--quote .area--text:nth-child(2) {
  line-height: clamp(25px, calc(25px + 5 * ((100vw - 768px) / 432)), 30px);
}
@media (min-width: 768px) {
  .area--quote .area--text:nth-child(2) {
    margin-left: 30px;
  }
}

.area--disruptor-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.area--disruptor-inner:before {
  content: svg-load("../images/layout/svg/branch.svg");
  position: absolute;
  top: 40px;
  left: -130px;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .area--disruptor-inner:before {
    content: svg-load("../images/layout/svg/branch-small.svg");
    left: 0;
  }
}
.area--disruptor-inner:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(131, 34, 122, 0.6);
}
.area--disruptor-text {
  width: 100%;
  text-align: center;
  z-index: 1;
  padding: 60px;
}
@media (max-width: 767.98px) {
  .area--disruptor-text {
    padding: 30px;
  }
}
.area--disruptor-text h2 {
  margin-bottom: 1rem;
  color: #fff;
}
.area--disruptor .area--thumbnail img {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}
.area--disruptor .area--thumbnail .media-wrapper--edit {
  z-index: 1;
}

.area--content-container {
  background: #F2F2F2;
  color: #000;
  position: relative;
}
.area--content-container {
  padding-top: clamp(60px, calc(60px + 40 * ((100vw - 768px) / 432)), 100px);
}
.area--content-container {
  padding-bottom: clamp(60px, calc(60px + 40 * ((100vw - 768px) / 432)), 100px);
}
@media (min-width: 768px) {
  .area--content-container h2.margin-top {
    margin-top: 50px;
  }
}
.area--content-container.branch:before {
  content: svg-load("../images/layout/svg/branch-big.svg");
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -2;
}
@media (max-width: 767.98px) {
  .area--content-container.branch:before {
    content: unset;
  }
}

/* Contact Map */
.contact_map {
  height: 50vh;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 5.3333333333vw;
}
@media (min-width: 768px) {
  .contact_map {
    margin-bottom: 4.1666666667vw;
  }
}
@media (min-width: 1200px) {
  .contact_map {
    margin-bottom: 50px;
  }
}

/* Alert */
.alert {
  padding: 8vw;
  border-width: 0.5333333333vw;
  border-style: solid;
  border-color: #83227A;
  border-radius: 5.3333333333vw;
  background: rgba(131, 34, 122, 0.2);
  color: #83227A;
  text-align: center;
  margin-bottom: 8vw;
}
@media (min-width: 768px) {
  .alert {
    padding: 30px;
  }
}
@media (min-width: 768px) {
  .alert {
    border-width: 2px;
  }
}
@media (min-width: 768px) {
  .alert {
    border-radius: 20px;
  }
}
@media (min-width: 768px) {
  .alert {
    margin-bottom: 30px;
  }
}