.area--disruptor {

    &-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &:before {
            content: svg-load('../images/layout/svg/branch.svg');
            position: absolute;
            top: 40px;
            left: -130px;
            z-index: 1;

            @include media-breakpoint-down(md) {
                content: svg-load('../images/layout/svg/branch-small.svg');
                left: 0;
            }
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba($brand-primary, 0.6);
        }
    }

    &-text {
        width: 100%;
        text-align: center;
        z-index: 1;
        padding: 60px;

        @include media-breakpoint-down(md) {
            padding: 30px;
        }

        h2 {
            margin-bottom: 1rem;
            color: $white;
        }
    }

    .area--thumbnail {

        img {
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
        }

        .media-wrapper--edit {
            z-index: 1;
        }
    }
}