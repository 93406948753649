@mixin fluid-property($property, $min-vw, $max-vw, $min-val, $max-val) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-val);
    $u4: unit($max-val);

    @if $min-val == $max-val {
        & {
            #{$property}: $min-val;
        }
    } @else if $property and $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            #{$property}: clamp(#{$min-val}, calc(#{$min-val} + #{strip-unit($max-val - $min-val)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})), #{$max-val});
        }
    }
};

@mixin fluid-type($min-vw, $max-vw, $min-val, $max-val) {
    @include fluid-property('font-size', $min-vw, $max-vw, $min-val, $max-val);
}

/*
  fluid mixin
  - provides a fluid layout for mobile and px layout for everything else
  - one value for both sizes
 */

@mixin fluidAll($property, $mobileValue, $value) {
    #{$property}: pxToVwMobile($mobileValue);
    @include media-breakpoint-up(md) {
        #{$property}: pxToVw($value);
    }
    @include media-breakpoint-up(xl) {
        #{$property}: $value;
    }
}

@mixin fluid($property, $value) {
    #{$property}: pxToVwMobile($value);
    @include media-breakpoint-up(md) {
        #{$property}: $value;
    }
}

/*
  fluidDiff mixin
  - provides a fluid layout for mobile and px layout for everything else
  - different values for both sizes
 */

@mixin fluidDiff($property, $mobileValue, $value) {
    #{$property}: pxToVwMobile($mobileValue);
    @include media-breakpoint-up(md) {
        #{$property}: $value;
    }
}

@mixin fluidDesktop($property, $value) {
    @include media-breakpoint-up(md) {
        #{$property}: pxToVw($value);
    }
    @include media-breakpoint-up(xl) {
        #{$property}: $value;
    }
}

@mixin fluidPlus($property, $value) {
    #{$property}: pxToVwPlus($value);
    @include media-breakpoint-up(xxxl) {
        #{$property}: $value;
    }
}

@mixin fluidOuter($property, $value) {
    #{$property}: pxToVwOuter($value);
    @include media-breakpoint-up(outer) {
        #{$property}: $value;
    }
}