.page-intro {

    h2 {
        margin-bottom: 1em;
    }

    &--wrap {
        display: flex;
        flex-wrap: wrap;
    }

    &--text {
        flex: 1 0 50%;
        padding: 10px 20px 20px 0;
    }

    &--info {
        flex: 1 0 50%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        @include media-breakpoint-down(sm) {
            justify-content: flex-start;
        }

        .info-wrapper {
            max-width: 250px;
            padding: 10px;

            & > p {
                margin-bottom: 2em;
            }
        }

        li {
            position: relative;
            padding-left: 50px;
            height: 45px;
            margin-bottom: 2em;

            p {
                margin-bottom: 0;
                height: 45px;
                display: table-cell;
                vertical-align: middle;
            }

            i {
                height: 45px;
                width: 45px;
                line-height: 45px;
                position: absolute;
                left: 0;
            }
        }
    }
}