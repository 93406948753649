html {
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
    width: 100%;

    @include media-breakpoint-down(md) {
        .offcanvas-nav--visible & {
            overflow-y: hidden;
        }
    }
}

::selection {
    background: $brand-primary;
    color: $white;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.page-wrap {
    overflow-x: hidden;
}