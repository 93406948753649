.cols {
    display: grid;
    grid-column-gap: $grid-gutter-width;
    grid-row-gap: $grid-gutter-width;

    @include media-breakpoint-down(md) {
        grid-column-gap: $grid-gutter-width / 2;
        grid-row-gap: $grid-gutter-width / 2;
    }

    &.no-gutter {
        grid-column-gap: 0;
        grid-row-gap: 0;
    }

    &--2 {
        @include media-breakpoint-up(sm) {
            grid-template-columns: repeat(2, 1fr);

            &.ratio {
                &--25-75 {
                    grid-template-columns: 25% 75%;
                }

                &--40-60 {
                    grid-template-columns: 40% 60%;
                }

                &--60-40 {
                    grid-template-columns: 60% 40%;
                }

                &--75-25 {
                    grid-template-columns: 75% 25%;
                }
            }
        }
    }

    &--3 {
        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(3, 1fr);

            &.ratio {
                &--50-25-25 {
                    grid-template-columns: 50% 25% 25%;
                }

                &--25-50-25 {
                    grid-template-columns: 25% 50% 25%;
                }

                &--25-25-50 {
                    grid-template-columns: 25% 25% 50%;
                }
            }
        }

        @include media-breakpoint-between(sm, md) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &--4 {
        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(4, 1fr);

            &.ratio {
                &--40-20-20-20 {
                    grid-template-columns: 40% 20% 20% 20%;
                }

                &--20-40-20-20 {
                    grid-template-columns: 20% 40% 20% 20%;
                }

                &--20-20-40-20 {
                    grid-template-columns: 20% 20% 40% 20%;
                }

                &--20-20-20-40 {
                    grid-template-columns: 20% 20% 20% 40%;
                }
            }
        }

        @include media-breakpoint-between(sm, md) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &--5 {
        @include media-breakpoint-up(xl) {
            grid-template-columns: repeat(5, 1fr);
        }

        @include media-breakpoint-between(lg, xl) {
            grid-template-columns: repeat(4, 1fr);
        }

        @include media-breakpoint-between(md, lg) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include media-breakpoint-between(sm, md) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &--6 {
        @include media-breakpoint-up(xl) {
            grid-template-columns: repeat(6, 1fr);
        }

        @include media-breakpoint-between(lg, xl) {
            grid-template-columns: repeat(4, 1fr);
        }

        @include media-breakpoint-between(md, lg) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include media-breakpoint-between(sm, md) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .col {
        &--2 {
            grid-column: auto / span 2;
        }

        &--3 {
            grid-column: auto / span 3;
        }

        &--4 {
            grid-column: auto / span 4;
        }

        &--5 {
            grid-column: auto / span 5;
        }

        &--6 {
            grid-column: auto / span 6;
        }
    }
}