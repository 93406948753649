.area {
    @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, 30px, 60px);
    @include fluid-property('margin-bottom', $viewport-width-min, $viewport-width-max, 30px, 60px);

    // individuelle Abstände
    &.margin {
        &-top--none {
            margin-top: 0;
        }

        &-top--tiny {
            margin-top: 10px;
        }

        &-top--small {
            margin-top: 20px;
        }

        &-top--medium {
            @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, 30px, 60px);
        }

        &-top--large {
            @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, 50px, 100px);
        }

        &-top--xlarge {
            @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, 65px, 125px);
        }

        &-bottom--none {
            margin-bottom: 0;
        }

        &-bottom--tiny {
            margin-bottom: 10px;
        }

        &-bottom--small {
            margin-bottom: 20px;
        }

        &-bottom--medium {
            @include fluid-property('margin-bottom', $viewport-width-min, $viewport-width-max, 30px, 60px);
        }

        &-bottom--large {
            @include fluid-property('margin-bottom', $viewport-width-min, $viewport-width-max, 50px, 100px);
        }

        &-bottom--xlarge {
            @include fluid-property('margin-bottom', $viewport-width-min, $viewport-width-max, 65px, 125px);
        }
    }

    > *:first-child {
        margin-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
    }

    &.is-narrow {
        margin-left: auto;
        margin-right: auto;
        max-width: to-em(960px);
    }

    &.full-width {
        position: relative;
        @include fullWidthViewport;
        left: 50%;
        transform: translateX(-50%);
    }

    &--thumbnail {
        img, video {
            display: block;
            width: 100%;
            height: auto;
        }
    }
}