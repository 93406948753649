.area--textmedia {

    .cols--2 {
        grid-template-columns: 1.3fr 1fr;
        @include media-breakpoint-down(md) {
            grid-template-columns: unset;
        }
    }

    .leaf:after {
        content: svg-load('../images/layout/svg/leaf.svg');
        position: absolute;
        left: 30px;
        top: -100px;

        @include media-breakpoint-down(md) {
            content: svg-load('../images/layout/svg/leaf-small.svg');
            top: -60px;
        }
    }

    .thumbnail--right {
        &.cols--2 {
            grid-template-columns: 1fr 1.3fr;
            @include media-breakpoint-down(md) {
                grid-template-columns: unset;

                .area--textmedia-media {
                    order: 1;
                }

                .area--textmedia--text {
                    order: 2;
                }
            }
        }

        .leaf:after {
            left: unset;
            right: 30px;
        }
    }

    .thumbnail--left, .thumbnail--right {
        position: relative;
    }

    .area--thumbnail {
        margin: 0;

        img {
            object-fit: cover;

            @include media-breakpoint-down(sm) {
                height: 400px;
            }
        }
    }

    .area--text {

        &-headline {
            padding-left: 30px;

            h3 {
                margin-bottom: 0;
            }

            @include media-breakpoint-up(xl) {
                padding-left: 50px;

                h3 {
                    margin-bottom: 0.5em;
                }
            }
        }

        &--inner {
            padding: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .btn-primary {
                margin-right: 1rem;
            }

            @include media-breakpoint-up(md) {
                padding-top: 0;
            }

            @include media-breakpoint-up(xl) {
                padding: 0 25px 100px 50px;
            }

            > *:first-child {
                margin-top: 0;
            }

            > *:last-child {
                margin-bottom: 0;
            }
        }

        &-content {

            &:before {
                content: '';
                position: absolute;
                right: -100px;
                left: 0;
                bottom: 0;
                background-color: $lighter-gray;
                z-index: -1;

                @include fluid-property('top', $viewport-width-min, $viewport-width-max, $h3-min-font-size, $h3-font-size);

                @include media-breakpoint-down(sm) {
                    top: 240px;
                    left: -30px !important;
                    right: -30px !important;
                }
            }
        }
    }

    &-media {
        padding-bottom: 30px;

        @include media-breakpoint-up(xl) {
            padding-left: 30px;
            padding-bottom: 100px;
        }
    }

    .area--thumbnail {
        position: relative;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
            width: 100%;
            height: 100%;

            &:before {
                display: block;
                content: '';
                width: 100%;
                height: 0;
                padding-bottom: 66.6666%;
                background: gray;
            }

            img, video {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                max-width: none;
            }
        }
    }

    .thumbnail--left {
        .area--text-headline {
            padding-left: 30px;

            @include media-breakpoint-up(xl) {
                padding-left: 25px;
            }
        }

        .area--text--inner {
            padding: 30px;

            @include media-breakpoint-up(xl) {
                padding: 0 50px 100px 25px;
            }
        }

        .area--textmedia-media {

            @include media-breakpoint-up(xl) {
                padding-right: 30px;
                padding-left: 0;
            }
        }

        .area--text-content:before {
            left: -100px;
            right: 0;
        }
    }

    // volle breite
    &.full-width {

        .area--textmedia {
            &-media {

                @include media-breakpoint-between(sm, xl) {
                    padding: 0 0 50px 60px;
                }

                @include media-breakpoint-up(xl) {
                    padding: 0 0 100px 100px;
                }
            }

            &--text {
                @include media-breakpoint-up(xl) {
                    padding-right: 100px;
                }
            }
        }

        .thumbnail--right {

            .area--text-headline {

                @include media-breakpoint-up(sm) {
                    padding-left: 60px;
                }

                @include media-breakpoint-up(xl) {
                    padding-left: 100px;
                }
            }

            .area--text--inner {

                @include media-breakpoint-up(sm) {
                    padding: 30px 30px 50px 60px;
                }

                @include media-breakpoint-up(xl) {
                    padding: 0 70px 100px 100px !important;
                }
            }

            .area--textmedia {

                &-media {

                    @include media-breakpoint-between(sm, xl) {
                        padding: 0 60px 50px !important;
                    }

                    @include media-breakpoint-up(xl) {
                        padding: 0 100px 100px 0 !important;
                    }
                }

                &--text {
                    @include media-breakpoint-up(xl) {
                        padding-left: 100px;
                        padding-right: 0;
                    }
                }
            }

            .area--text-content:before {
                @include fluid-property('left', $viewport-width-min, $viewport-width-max, 0px, 100px);
                right: 0;
            }
        }

        .area--text {

            &-headline {
                padding: 0 30px;

                @include media-breakpoint-up(xl) {
                    padding-left: 70px;
                }
            }

            &--inner {
                padding: 30px;
                padding-bottom: 50px;

                @include media-breakpoint-up(sm) {
                    padding: 30px 60px 50px 30px;
                }

                @include media-breakpoint-up(xl) {
                    padding: 0 100px 100px 70px;
                }
            }

            &-content:before {
                left: 0;
                @include fluid-property('right', $viewport-width-min, $viewport-width-max, 0px, 100px);
            }
        }

        .area--textmedia-media {
            @include media-breakpoint-down(sm) {
                padding: 0 30px;
            }
        }
    }

    &.text-center-vertically {
        .area--text {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
        }
    }

    .cols.thumbnail--right {
        > *:first-child {
            @include media-breakpoint-up(sm) {
                order: 2;
            }
        }

        > *:last-child {
            @include media-breakpoint-up(sm) {
                order: 1;
            }
        }
    }
}